<template>
    <div class="avatar" v-bind="$attrs">
        <gb-image
            class="image"
            fit="cover"
            image-style="avatar"
            :src="value"
            :default-src="defaultSrc"
        ></gb-image>
        <gb-upload
            v-model="value"
            :description="photoDescription"
            @success="uploadSuccess"
        >
            <el-button class="upload-button">{{ buttonTitle }}</el-button>
        </gb-upload>
    </div>
</template>

<script>
import gbUpload from '@/components/upload'
import gbImage from '@/components/image'
import defaultImages from './_photo'
import permission from '@/utils/permission'

export default {
    name: 'GbAvatar',
    components: { gbUpload, gbImage },
    props: {
        value: String,
        description: String,
        title: String,
        defaultImage: String,
        category: String,
        valueId: Number
    },
    data () {
        return {
            buttonTitle: '上传照片',
            photoDescription: null,
            defaultSrc: defaultImages.defaultSrc,
            showFace: false
        }
    },
    created () {
        let facePermission
        let info = defaultImages[this.category]
        if (info) {
            facePermission = info.facePermission
            this.faceFrom = info.faceFrom
            this.buttonTitle = info.title
            this.photoDescription = info.description
            this.defaultSrc = info.image
        }
        if (this.title) {
            this.buttonTitle = this.title
        }
        if (this.description) {
            this.photoDescription = this.description
        }
        if (this.defaultImage) {
            this.defaultSrc = this.defaultImage
        }
        if (
            this.valueId &&
            facePermission &&
            permission.hasPermission(facePermission)
        ) {
            this.showFace = true
        }
    },
    methods: {
        uploadSuccess (url) {
            this.$emit('input', url)
        }
        // updateFace() {
        //     axios
        //         .fetch('userServer', '/user/face/' + this.category, {
        //             valueId: this.valueId
        //         })
        //         .then(res => {
        //             this.$message.success('更新成功')
        //         })
        // }
    }
}
</script>

<style lang="less" scoped>
.avatar {
    text-align: center;

    .image {
        width: 150px !important;
        height: 200px;
    }

    .upload-button {
        width: 150px !important;
        margin-top: 20px;
    }

    .face-button {
        width: 150px !important;
        margin-top: 10px;
    }
}
</style>
