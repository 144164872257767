<template>
    <div>
        <el-form
            ref="searchForm"
            :inline="true"
            class="page-search"
            :model="searchData"
        >
            <div class="left">
                <el-form-item label="">
                    <gb-dictionary
                        v-show="isEdit"
                        v-model="searchData.indicatorType"
                        category="searchData.indicatorType"
                        group="KPI考核指标类型"
                        placeholder="请选择指标类型"
                        clearable
                    ></gb-dictionary>
                </el-form-item>

                <el-form-item label="">
                    <el-input
                        v-model="searchData.title"
                        placeholder="名称"
                        clearable
                    ></el-input>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="search"
                        >
                            查询
                        </el-button>
                        <el-button
                            icon="el-icon-refresh-right"
                            @click="reset"
                        >
                            重置
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <!-- <span @click="add()"> -->
                <span @click="add()">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang">新增</i>
                </span>

                <!-- <span @click="toTemplate()">
          <i class="el-icon-s-promotion">考核模板</i>
        </span> -->
            </div>
            <div class="gb-table">
                <el-table v-loading="isLoading" :data="listData" height="500">
                    <el-table-column min-width="50" label="序号" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                (searchData.current - 1) * searchData.size + scope.$index + 1
                            }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="indicatorType"
                        label="类型"
                        min-width="50"
                    >
                        <template slot-scope="scope">
                            　<span>
                                {{ scope.row.indicatorType | dictionaryFilter(vueInstance, 'KPI考核指标类型') }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="title"
                        label="名称"
                        min-width="50"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="description"
                        label="评分说明"
                        min-width="100"
                        align="center"
                    ></el-table-column>
                    <el-table-column prop="scoreStart" label="得分范围" min-width="100">
                        　　<template slot-scope="scope">
                            　<span>
                                {{ scope.row.scoreStart }} ~{{ scope.row.scoreEnd }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="dispIndex"
                        label="排序"
                        min-width="50"
                    ></el-table-column>
                    <el-table-column label="操作" align="center" min-width="100">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                @click="dismisson(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                @click="deleteItem(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :total="dataCount"
                    :current-page="searchData.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchData.size"
                    layout="total, sizes,prev, pager, next, jumper"
                    @current-change="changePage"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>

        <!--新增考核信息-->

        <el-dialog
            :title="operation ? '新增考核指标' : '编辑考核指标'"
            :visible.sync="showCreateOrUpdate"
            width="600px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="indicatorsForm"
                :rules="quitRules"
                :model="indicatorsForm"
                label-width="80px"
            >
                <!-- <el-form-item label="类型">
          <el-select
            size="small"
            v-model="indicatorsForm.indicatorType"
            placeholder="请选择类型"
          >
            <el-option label="业务指标" value="1"></el-option>
            <el-option label="行为指标" value="2"></el-option>
            <el-option label="附加项" value="3"></el-option>
          </el-select>
        </el-form-item> -->

                <el-form-item label="类型" prop="indicatorType">
                    <gb-dictionary
                        v-model="indicatorsForm.indicatorType"
                        category="indicatorsForm.indicatorType"
                        :dict-bus="vueInstance"
                        group="KPI考核指标类型"
                    ></gb-dictionary>
                </el-form-item>

                <el-form-item label="名称" prop="title">
                    <el-input
                        v-model="indicatorsForm.title"
                        placeholder="请输入指标名称"
                        style="width: 80%; display: block"
                        size="small"
                        maxlength="20"
                        show-word-limit
                    ></el-input>
                </el-form-item>

                <el-form-item label="评分说明">
                    <el-input
                        v-model="indicatorsForm.description"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 10 }"
                        style="width: 80%; display: block"
                        size="small"
                        placeholder="请输入评分说明"
                        maxlength="200"
                        show-word-limit
                    ></el-input>
                </el-form-item>

                <el-form-item
                    label="得分范围"
                    prop="scoreStart"
                    :rules="[
                        { required: true, message: '请输入', trigger: 'blur' },
                        { validator: this.numberRule, trigger: 'blur'}
                    ]"
                >
                    <div style="width: 300px">
                        <el-row>
                            <el-col :span="11">
                                <el-input
                                    v-model="indicatorsForm.scoreStart"
                                    style="width: 90%; display: block"
                                    size="small"
                                    type="number"
                                    placeholder="最低分"
                                    on-keypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                ></el-input>
                            </el-col>
                            <el-col :span="2"> ~ </el-col>
                            <el-col :span="11">
                                <el-form-item
                                    prop="scoreEnd"
                                    :rules="[
                                        { required: true, message: '请输入', trigger: 'blur' },
                                        { validator: this.endNumberRule, trigger: 'blur'}
                                    ]"
                                >
                                    <el-input
                                        v-model="indicatorsForm.scoreEnd"
                                        style="width: 90%; display: block"
                                        size="small"
                                        type="number"
                                        on-keypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                        placeholder="最高分"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-form-item>

                <el-form-item label="排序" prop="dispIndex">
                    <el-input-number
                        v-model="indicatorsForm.dispIndex"
                        style="width: 30%; display: block"
                        placeholder="请输入排序值"
                        size="small"
                        :min="0"
                        on-keypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    ></el-input-number>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitDismisson">确 定</el-button>
                <el-button @click="showCreateOrUpdate = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import utilsTable from '@/utils/table'
import department from '@/components/Department'
import ehr from '@/services/ehr'
import gbDictionary from '@/components/dictionary'
import { mapState } from 'vuex'
import breadSlot from '../components/breadSlot'
import BreadSlotMixin from 'mixins/breadSlot' // 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'EmployeeFile',
    components: { gbDictionary },
    filters: {
        filterStatus: function (value) {
            return value ? '是' : '否'
        },
        dictionaryFilter: gbDictionary.filter
    },
    mixins: [breadSlotMixin],
    data () {
        const numberRule = (rule, value, callback) => {
            if (Number(value) > this.indicatorsForm.scoreEnd) {
                callback(new Error('最小值不能大于最大值'))
            } else {
                callback()
            }
            if (Number(value) <= -1000 || Number(value) > 1000) {
                callback(new Error('请在-1000到1000之间填写数字'))
            } else {
                callback()
            }
        }

        const endNumberRule = (rule, value, callback) => {
            if (Number(value) <= -1000 || Number(value) > 1000) {
                callback(new Error('请在-1000到1000之间填写数字'))
            } else {
                callback()
            }
        }

        return {
            numberRule: numberRule,
            endNumberRule: endNumberRule,
            vueInstance: this,
            visibleQuit: false,
            showCreateOrUpdate: false,
            listData: [],
            dataCount: 0,
            staffForm: {},
            isEdit: true,
            quitForm: {},
            indicatorsForm: {},
            operation: true, // true新增，fase编辑
            isLoading: false,
            value: '',
            searchData: {
                employeeNameLike: null,
                joinDateFrom: '',
                joinDateTo: '',
                groupId: null,
                selectStatus: [0, 1],
                size: 20,
                current: 1,
                title: ''
            },
            quitRules: {
                indicatorType: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: ['blur', 'change']
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: ['blur', 'change']
                    }
                ],
                scoreStart: [
                    {
                        required: true,
                        message: '请输入范围',
                        trigger: ['blur', 'change']
                    }
                ],

                scoreEnd: [
                    {
                        required: true,
                        message: '请输入范围',
                        trigger: ['blur', 'change']
                    }
                ],

                dispIndex: [
                    {
                        required: true,
                        message: '请输入排序值',
                        trigger: ['blur', 'change']
                    }
                ]
            }
        }
    },
    computed: {
        dateValues: {
            get () {
                return [this.searchData.joinDateFrom, this.searchData.joinDateTo]
            },
            set (value) {
                this.searchData.joinDateFrom = value ? value[0] : ''
                this.searchData.joinDateTo = value ? value[1] : ''
            }
        }
    },

    mounted () {

    },
    activated () {
        this.addSlot(breadSlot)
    },
    created () {
        gbDictionary.init(this.vueInstance, ['KPI考核指标类型'])
        this.fetchData()
    },
    methods: {
        ...utilsTable,
        registerPlaceFormatter (row, column, value, index) {
            let objects = this.registerPlaces
            for (let key of Object.keys(objects)) {
                if (objects[key].itemValue == value) {
                    return objects[key].itemName
                }
            }
        },

        toTemplate () {
            this.$router.push({
                path: '/oa/assessTemplate'
            })
        },

        search () {
            this.changePage(1)
        },
        searchByStatus (selectStatus) {
            this.searchData.selectStatus = selectStatus
            this.changePage(1)
        },
        reset () {
            this.$refs['searchForm'].resetFields()
            this.searchData.title = ''

            this.searchData.indicatorType = null
            this.changePage(1)
        },
        changePage (value) {
            this.searchData.current = value
            this.fetchData()
        },
        handleSizeChange (val) {
            this.searchData.size = val
            this.fetchData()
        },
        async fetchData () {
            this.isLoading = true
            const res = await ehr.getIndicatorsPage(this.searchData)
            this.listData = res.data || []
            this.dataCount = res.total
            this.isLoading = false
        },

        edit (data) {
            this.$store.commit('setShowParent', 1)
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee/edit?currentTab=edit&sign=edit',
                query: {
                    employeeId: employeeId
                }
            })
        },
        clearValidate () {
            if (this.$refs.indicatorsForm) {
                console.log('开始清除')
                this.$nextTick(() => {
                    this.$refs.indicatorsForm.clearValidate()
                })
            }
        },
        add () {
            //   this.$router.push({
            //     path: "/oa/employee-add",
            //   })
            this.indicatorsForm = { dispIndex: 0 }
            this.clearValidate()
            this.operation = true
            this.showCreateOrUpdate = true
        },
        seeDetail (data) {
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee-detail',
                query: { employeeId }
            })
        },
        dismisson (data) {
            this.operation = false
            this.indicatorsForm = JSON.parse(JSON.stringify(data))
            this.showCreateOrUpdate = true
        },
        submitDismisson () {
            this.$refs['indicatorsForm'].validate(async (valid) => {
                if (!valid) {
                    return false
                } else {
                    if (this.operation) {
                        await ehr.add(this.indicatorsForm)
                    } else {
                        await ehr.edit(this.indicatorsForm)
                    }

                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                    this.showCreateOrUpdate = false
                    this.fetchData()
                }
            })
        },
        async deleteItem (row) {
            console.log('删除数据：' + JSON.stringify(row))

            let that = this
            this.$confirm('确认删除吗？').then(async () => {
                try {
                    const res = await ehr.delete(row)
                    that.$message.success('删除成功')
                    that.fetchData()
                } catch (error) {
                    console.log(error)
                }
            })
        },
        datasync () {
            this.$axios.fetch('datasyncServer', '/district/cityData').then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.data)
                } else {
                    this.$message.error(res.message)
                }
            })
            this.$axios.fetch('datasyncServer', '/district/employee').then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.data)
                    this.initData()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        showWechat (item) {
            this.$refs['wechat'].showQrCode(item.employeeId, item.employeeName)
        }
    }
}
</script>

<style lang="less" scoped>
    /* stylelint-disable */
.name-tips {
  margin-left: 30px;
}
.error-tips {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
}
#quitDiagsImg {
  width: 150px !important;
  height: 197px !important;
}
.el-textarea {
  display: inline-block;
  width: 220px;
}
.center {
  text-align: center;
}
</style>
