<template>
    <div>
        <div
            id="luckysheet"
            style="position: absolute; top: 130px; bottom: 0; left: 0; width: 100%; padding: 0; margin: 0;"
        ></div>

        <div style="position: absolute; top: 130px; right: 100px; width: 100px; height: 50px;">
            <el-button type="primary" round @click="downloadExcel">保存</el-button>
        </div>
        <div
            v-show="isMaskShow"
            style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000000; display: flex; align-items: center; justify-content: center; font-size: 40px; text-align: center; background: rgba(255, 255, 255, 0.8);"
        >
            Downloading
        </div>
    </div>
</template>

<script>
import LuckyExcel from 'luckyexcel'
// 导入库export.js 这个文件是es6的，不能在普通的HTML文件直接引入js文件（虽然都是js文件，但是有区别，具体请百度es6与es5）！需要把es6转es5才可以直接引入使用！
import { testaaa, exportExcel } from './export'
import document from '@/services/document'
import settings from '@/settings'
import axios from 'axios'
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data () {
        return {
            selected: '',
            diaplayName: null,
            id: null,
            servername: null,
            version: null,
            fileNo: null,
            options: [
                ''
            ],
            isMaskShow: false,
            getInfoUrl: null,
            getInfoUrlByKey: null,
            mqttUrl: null,
            mqttPort: null,
            editFlag: false
        }
    },
    // mounted () {
    // },
    created () {
        this.displayName = this.$store.getters.currentUser.loginName
        this.id = this.$route.query.id
        this.servername = this.$route.query.servername
        this.version = this.$route.query.version
        this.getExcelInfo()
        // 获得路径
        this.getInfoUrl = settings.servers.documentServer + '/excelDocument/info'
        this.getInfoUrlByKey = settings.servers.documentServer + '/excelDocument/infoByKey'
        this.mqttUrl = settings.servers.mqttServer
        this.mqttPort = settings.servers.mqttPort
    },
    methods: {

        async getExcelInfo () {
            let formData = {
                id: this.id,
                servername: this.servername,
                version: this.version
            }
            const res = await document.getExcelDocumentInfoByKey(formData)

            if (res.data.excelInfo) {
                console.log('之前保存过')
                this.id = res.data.id
                this.editFlag = res.data.editFlag
                this.init()
            } else {
                this.editFlag = res.data.editFlag
                console.log('之前没有保存过')
                this.fileNo = res.data.fileCode
                this.id = res.data.id
                this.selectExcel()
            }
        },

        init () {
            var autoSave
            var userName = this.displayName
            var servername = this.servername
            var version = this.version
            var mqttUrl = this.mqttUrl
            var mqttPort = this.mqttPort
            var getInfoUrl = this.getInfoUrl
            var id = this.id
            var updateUrl = null
            var allowUpdate = this.editFlag
            if (this.editFlag) {
                updateUrl = 'can edit'
            }
            var options = {
                lang: 'zh',
                container: 'luckysheet',
                allowUpdate: allowUpdate,
                loadUrl: getInfoUrl,
                token: this.$store.getters.currentUser.id,
                mqttUserName: this.$store.getters.currentUser.userId,
                gridKey: id,
                myFolderUrl: '', // 左上角<返回按钮的链接
                userId: this.$store.getters.currentUser.employeeId,
                userName: userName,
                servername: servername,
                mqttUrl: mqttUrl,
                mqttPort: mqttPort,
                version: version,
                updateUrl: updateUrl,
                hook: {
                    workbookCreateBefore: function (e) {
                        console.log('开始创建表格')
                    },
                    updated: function (e) {
                        // 监听更新,并在3s后自动保存
                        if (autoSave) clearTimeout(autoSave)
                        // $(luckysheet_info_detail_save).text('已修改')
                        if (!allowUpdate) {
                            return true
                        }
                        autoSave = setTimeout(function () {
                            var excel = window.luckysheet.getAllSheets()
                            // 去除临时数据,减小体积
                            for (var i in excel) excel[i].data = undefined
                            let formData = {
                                id: id,
                                excelInfo: JSON.stringify(excel)
                            }
                            const res = document.updateExcelInfo(formData)
                        }, 3 * 1000)
                        return true
                    }
                }
                // updateUrl: 'ws://' + location.hostname + ':11551?name=' + 'test'
                // updateUrl: 'ws://139.198.166.123:8083/mqtt'
            }
            console.log(window.luckysheet)
            window.luckysheet.create(options)
        },
        // 获得最新信息

        uploadExcel (evt) {
            var _this = this
            const files = evt.target.files
            if (files == null || files.length == 0) {
                alert('No files wait for import')
                return
            }

            let name = files[0].name
            let suffixArr = name.split('.')
            let suffix = suffixArr[suffixArr.length - 1]
            if (suffix != 'xlsx') {
                alert('Currently only supports the import of xlsx files')
                return
            }
            LuckyExcel.transformExcelToLucky(files[0], function (
                exportJson,
                luckysheetfile
            ) {
                if (
                    exportJson.sheets == null ||
                    exportJson.sheets.length == 0
                ) {
                    alert(
                        '仅支持 .xlsx 文件!'
                    )
                    return
                }
                window.luckysheet.destroy()
                var autoSave
                var userName = _this.displayName
                var id = _this.id
                window.luckysheet.create({
                    lang: 'zh',
                    container: 'luckysheet', // luckysheet is the container id
                    showinfobar: false,
                    data: exportJson.sheets,
                    title: exportJson.info.name,
                    upload: true,
                    userInfo: exportJson.info.name.creator,
                    updateUrl: 'ws://' + location.hostname + ':11551?name=' + 'test',
                    allowUpdate: true,
                    loadUrl: 'http://localhost:11427/get',
                    token: _this.$store.getters.currentUser.id,
                    gridKey: id,
                    userId: _this.$store.getters.currentUser.employeeId,
                    userName: userName
                })
            })
        },
        async selectExcel (evt) {
            var _this = this
            //
            // const name = evt.target.options[evt.target.selectedIndex].innerText
            let query = { fileNo: this.fileNo }
            const res = await document.getDownloadableUrlByFileNo(query)
            // const value = 'https://minio.cnbabylon.com/public/luckysheet/Student%20calendar%20%28Mon%29.xlsx'
            const value = res.data
            if (value == '') {
                return
            }
            this.isMaskShow = true

            LuckyExcel.transformExcelToLuckyByUrl(
                value,
                name,
                (exportJson, luckysheetfile) => {
                    if (
                        exportJson.sheets == null ||
                        exportJson.sheets.length == 0
                    ) {
                        alert(
                            'Failed to read the content of the excel file, currently does not support xls files!'
                        )
                        return
                    }

                    this.isMaskShow = false
                    window.luckysheet.destroy()
                    var autoSave
                    var userName = _this.displayName
                    var id = _this.id
                    var token = _this.$store.getters.currentUser.id
                    let formData = {
                        id: id,
                        excelInfo: JSON.stringify(exportJson.sheets)
                    }
                    var mqttUrl = this.mqttUrl
                    var mqttPort = this.mqttPort
                    var getInfoUrl = this.getInfoUrl
                    var allowUpdate = this.editFlag
                    var updateUrl = null
                    if (this.editFlag) {
                        updateUrl = 'can edit'
                    }
                    const res = document.updateExcelInfo(formData)
                    window.luckysheet.create({
                        lang: 'zh',
                        container: 'luckysheet', // luckysheet is the container id
                        showinfobar: false,
                        data: exportJson.sheets,
                        title: exportJson.info.name,
                        userInfo: exportJson.info.name.creator,
                        mqttUrl: mqttUrl,
                        mqttPort: mqttPort,
                        upload: true,
                        updateUrl: updateUrl,
                        allowUpdate: allowUpdate,
                        loadUrl: getInfoUrl,
                        token: _this.$store.getters.currentUser.id,
                        gridKey: id,
                        mqttUserName: this.$store.getters.currentUser.userId,
                        userId: _this.$store.getters.currentUser.employeeId,
                        userName: userName,
                        hook: {
                            updated: function (e) {
                                // 监听更新,并在3s后自动保存
                                if (autoSave) clearTimeout(autoSave)
                                // $(luckysheet_info_detail_save).text('已修改')
                                if (!allowUpdate) {
                                    return true
                                }
                                autoSave = setTimeout(function () {
                                    var excel = window.luckysheet.getAllSheets()
                                    // 去除临时数据,减小体积
                                    for (var i in excel) excel[i].data = undefined
                                    let formData = {
                                        id: id,
                                        excelInfo: JSON.stringify(excel)
                                    }
                                    const res = document.updateExcelInfo(formData)
                                }, 1 * 1000)
                                return true
                            }
                        }
                    })
                }
            )
        },

        // 更新excel数据
        updateExcelInfo () {

        },
        async downloadExcel () {
            var _this = this
            if (!this.editFlag) {
                this.$message.error('该文件不允许编辑')
                return
            }
            var liu = await exportExcel(window.luckysheet.getAllSheets())
            // let formParm = { file: liu }
            const formData = new FormData()
            formData.append('file', liu)
            formData.append('fileId', this.id)
            axios({
                method: 'post',
                url:
            settings.servers.documentServer +
            '/excelDocument/singleUpload', // 请求地址
                data: formData,
                responseType: 'arraybuffer',
                headers: {
                    authToken: _this.$store.getters.currentUser.id || ''
                    // 'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                console.log(res)

                if (res.code == 200) {
                    // 跳转到列表页
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
    margin: 40px 0 0;
}

ul {
    padding: 0;
    list-style-type: none;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
