import axios from '@/utils/https'

export default {
    // -----------ehr相关接口--------------

    // 分页获取考核指标列表
    getIndicatorsPage(query) {
        return axios.fetch('hrbpServer', '/kpiindicator/kpiindicator-page', query)
    },

    //删除考核指标数据
    delete(query) {
        return axios.fetch('hrbpServer', '/kpiindicator/delete', query)
    },
    //新增考核指标
    add(query) {
        return axios.fetch('hrbpServer', '/kpiindicator/save', query)
    },
    //编辑考核指标
    edit(query) {
        return axios.fetch('hrbpServer', '/kpiindicator/update', query)
    },

    // 分页获取考核模板列表
    getTemplatePage(query) {
        return axios.fetch('hrbpServer', '/kpitemplate/kpitemplate-page', query)
    },

    //新增考核模板
    addTemplate(query) {
        return axios.fetch('hrbpServer', '/kpitemplate/save', query, 'json')
    },

    //考核模板详情
    templateInfo(query) {
        return axios.fetch('hrbpServer', '/kpitemplate/info', query)
    },

    //编辑考核模板
    editTemplate(query) {
        return axios.fetch('hrbpServer', '/kpitemplate/update', query,'json')
    },

    //删除考核模板
    deleteTempate(query){
        return axios.fetch('hrbpServer', '/kpitemplate/delete', query)
    },

    //发起考核接口
    launchAdd(query){
        return axios.fetch('hrbpServer', '/kpiestimate/save', query,'json')
    },

    //考核列表
    estimateList(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/kpiestimateemployee-page', query)
    },

    //考核详情
    estimateInfo(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/info', query)
    },

    //终止考核
    deleteEstimate(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/delete', query,'json')
    },
    //最终打分接口
    assess(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/assess', query,'json')
    },

    //查询首页数据
    kpiestimateForNine(query){
        return axios.fetch('hrbpServer', '/index/kpiestimateForNine', query,'json')
    },
     //查询首页Kpi数据
     kpiestimateForKpi(query){
        return axios.fetch('hrbpServer', '/index/kpiestimateForKpi', query,'json')
    },
    //驳回
    reject(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/reject', query,'json')
    },
     //九宫格列表
     kpiestimatePage(query){
        return axios.fetch('hrbpServer', '/kpiestimate/kpiestimate-page', query)
    },

      //九宫格详情
      kpiestimateInfo(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/infoForNine', query,'json')
    },
    //九宫格终止考核
    deleteForNine(query){
        return axios.fetch('hrbpServer', '/kpiestimateemployee/deleteForNine', query,'json')
    },

    //岗位需求列表
    jobRequirement(query){
        return axios.fetch('hrbpServer', '/oajobrequirement/oajobrequirement-page', query)
    },
    //提交岗位信息
    addJobRequirement(query){
        return axios.fetch('hrbpServer', '/oajobrequirement/save', query,'json')
    },
    updateJobRequirement(query){
        return axios.fetch('hrbpServer', '/oajobrequirement/update', query,'json')
    },
    //工作台待招岗位信息
    jobRequirementInde(query){
        return axios.fetch('hrbpServer', '/index/jobRequirement', query,'json')
    },



    

}

