<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="KPI考核" name="first">
                <div>
                    <el-form
                        ref="searchForm"
                        :inline="true"
                        class="page-search"
                        :model="searchData"
                    >
                        <div class="left">
                            <el-form-item label="" prop="title">
                                <el-input
                                    v-model="searchData.title"
                                    clearable
                                    placeholder="考核名称"
                                ></el-input>
                            </el-form-item>

                            <el-form-item label="" prop="groupId">
                                <gb-department
                                    ref="oaGroup"
                                    v-model="searchData.groupId"
                                    class="oaGroup"
                                    placeholder="请选择组织"
                                    api-name="getAllDepts"
                                    :disabled-ids="ownedDeptIds"
                                    :is-cascader="true"
                                    :show-all-levels="false"
                                    value-filed="id"
                                    :expand-trigger="'click'"
                                    label-filed="groupName"
                                    @change="handleDeptChange"
                                >
                                </gb-department>
                            </el-form-item>
                            <el-form-item label="" prop="employeeId">
                                <gb-employee
                                    ref="employee2"
                                    v-model="searchData.employeeId"
                                    clearable
                                    placeholder="请选择被考核人"
                                    api-name="getEmlpoyeesList"
                                    :is-cascader="false"
                                    :value-filed="'employeeId'"
                                    :label-filed="'employeeName'"
                                ></gb-employee>
                            </el-form-item>

                            <el-form-item label="" prop="status">
                                <el-select
                                    v-model="searchData.status"
                                    placeholder="请选择状态"
                                    clearable
                                >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="right">
                            <div class="search-btn">
                                <div class="custom-btn">
                                    <el-button type="primary" @click="search">查询</el-button>
                                    <el-button @click="reset">重置</el-button>
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div class="page-content">
                        <div class="gb-table">
                            <el-table class="gb-table" :data="listData">
                                <el-table-column min-width="50" label="序号" align="center">
                                    <template slot-scope="scope">
                                        <span>{{
                                            (searchData.current - 1) * searchData.size +
                                                scope.$index +
                                                1
                                        }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    prop="title"
                                    label="考核名称"
                                ></el-table-column>

                                <el-table-column
                                    prop="indicatorType"
                                    label="类型"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        　<span>
                                            {{
                                                scope.row.templateType
                                                    | dictionaryFilter(vueInstance, "KPI考核模板类型")
                                            }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="templateTitle"
                                    label="模板名称"
                                ></el-table-column>
                                <el-table-column prop="groupId" label="组织">
                                    <template slot-scope="scope">
                                        {{ filterDept(scope.row.groupId) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="employeeName"
                                    label="被考核人"
                                ></el-table-column>
                                <el-table-column
                                    prop="estimatorName"
                                    label="考核人"
                                ></el-table-column>
                                <el-table-column prop="status" label="状态">
                                    <template slot-scope="scope">
                                        　<span>
                                            {{
                                                scope.row.status
                                                    | dictionaryFilter(vueInstance, "KPI考核评分状态")
                                            }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="estimateValue"
                                    label="最终得分"
                                ></el-table-column>

                                <el-table-column
                                    prop="updateTime"
                                    label="考核周期"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.dateStart }} ~ {{ scope.row.dateEnd }}
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    prop="createTime"
                                    label="发起时间"
                                ></el-table-column>

                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <div style="display: flex; justify-content: center;">
                                            <el-button
                                                size="mini"
                                                type="text"
                                                @click="getDetails(scope.row, scope.$index)"
                                            >
                                                详情
                                            </el-button>
                                            <div v-permission="'gbei.oa.hrbp.kpi.listall'" style="margin-left: 10px;">
                                                <el-button
                                                    v-if="scope.row.status != 99"
                                                    size="mini"
                                                    type="text"
                                                    @click="removeItem(scope.row)"
                                                >
                                                    终止考核
                                                </el-button>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination
                                :total="dataCount"
                                :current-page="searchData.current"
                                :page-size="searchData.size"
                                layout="total, prev, pager, next, jumper"
                                @current-change="changePage"
                            ></el-pagination>
                        </div>
                    </div>
                    <gb-password ref="thePassword"></gb-password>
                </div>
            </el-tab-pane>
            <!--九宫格页面-->
            <el-tab-pane label="九宫格人才盘点" name="second">
                <div>
                    <el-form
                        ref="searchFormNine"
                        :inline="true"
                        class="page-search"
                        :model="searchData"
                    >
                        <div class="left">
                            <el-form-item label="" prop="title">
                                <el-input
                                    v-model="searchData.title"
                                    clearable
                                    placeholder="考核名称"
                                    prop="titleNine"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <gb-employee
                                    ref="employee"
                                    v-model="searchData.estimatorId"
                                    clearable
                                    placeholder="考核人"
                                    api-name="getEmlpoyeesList"
                                    :is-cascader="false"
                                    :value-filed="'employeeId'"
                                    :label-filed="'employeeName'"
                                ></gb-employee>
                            </el-form-item>

                            <el-form-item>
                                <department
                                    ref="dept"
                                    v-model="searchData.groupId"
                                    class="dept"
                                    placeholder="请选择组织"
                                    api-name="getAllDepts"
                                    :is-cascader="true"
                                    value-filed="id"
                                    label-filed="groupName"
                                />
                            </el-form-item>

                            <!-- <el-form-item label="">
                <el-input
                  clearable
                  v-model="searchData.title"
                  placeholder="考核名称"
                ></el-input>
              </el-form-item> -->

                            <el-form-item label="">
                                <el-date-picker
                                    v-model="dateRange"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="周期开始日期"
                                    end-placeholder="周期结束日期"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    :clearable="true"
                                    @change="dateChange"
                                >
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="">
                                <el-select v-model="searchData.status" placeholder="请选择状态" clearable>
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="right">
                            <div class="search-btn">
                                <div class="custom-btn">
                                    <el-button type="primary" @click="searchNine">查询</el-button>
                                    <el-button @click="resetNine">重置</el-button>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>

                <div class="page-content">
                    <div class="gb-table">
                        <el-table class="gb-table" :data="listData">
                            <el-table-column min-width="50" label="序号" align="center">
                                <template slot-scope="scope">
                                    <span>{{
                                        (searchData.current - 1) * searchData.size +
                                            scope.$index +
                                            1
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="groupId" label="组织">
                                <template slot-scope="scope">
                                    {{ filterDept(scope.row.groupId) }}
                                </template>
                            </el-table-column>

                            <el-table-column prop="title" label="考核名称"></el-table-column>
                            <el-table-column
                                prop="estimatorName"
                                label="考核人"
                            ></el-table-column>
                            <el-table-column prop="status" label="状态">
                                <template slot-scope="scope">
                                    　<span>
                                        {{
                                            scope.row.status
                                                | dictionaryFilter(vueInstance, "KPI考核评分状态")
                                        }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="status" label="考核周期">
                                　<template slot-scope="scope">
                                    　<span>
                                        {{ scope.row.dateStart }} ~{{ scope.row.dateEnd }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                prop="createTime"
                                label="发起时间"
                            ></el-table-column>

                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <div style="display: flex; justify-content: center;">
                                        <el-button
                                            size="mini"
                                            type="text"
                                            @click="getDetailsNine(scope.row)"
                                        >
                                            详情
                                        </el-button>
                                        <el-button
                                            v-if="scope.row.status != 99"
                                            v-permission="'gbei.oa.hrbp.kpi.listall'"
                                            size="mini"
                                            type="text"
                                            @click="removeItemNine(scope.row)"
                                        >
                                            终止考核
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            :total="dataCount"
                            :current-page="searchData.pageIndex"
                            :page-size="searchData.pageSize"
                            layout="total, prev, pager, next, jumper"
                            @current-change="changePage"
                        ></el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import gbEmployee from '@/components/Department'
import gbPassword from '@/components/password'
import gbDictionary from '@/components/dictionary'
import department from '@/components/Department'
import gbDepartment from '@/components/Department'
import ehr from '@/services/ehr'
import { mapGetters } from 'vuex'
import breadSlot from '../components/breadSlot'
import BreadSlotMixin from 'mixins/breadSlot' // 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'UserMng',
    components: {
        gbEmployee,
        gbPassword,
        gbDictionary,
        department,
        gbDepartment
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            vueInstance: this,
            listData: [],
            dataCount: 0,
            currentItem: {},
            editVisible: false,
            editTitle: '',
            bussinessDepts: [],
            type: null,
            activeName: 'first',
            searchData: {
                userName: '',
                displayName: '',
                employeeId: null,
                size: 10,
                current: 1,
                title: '',
                status: ''
            },
            dateRange: [],
            options: [
                {
                    value: 0,
                    label: '待评分'
                },
                {
                    value: 10,
                    label: '待审核'
                },
                {
                    value: 20,
                    label: '待确认'
                },
                {
                    value: 99,
                    label: '已完成'
                }
            ]
        }
    },
    created () {
        gbDictionary.init(this.vueInstance, ['KPI考核模板类型', 'KPI考核评分状态'])
        this.type = this.$route.query.type
        if (this.type && this.type == 9) {
            this.activeName = 'second'
            this.kpiestimatePage()
        } else {
            this.fetchData()
        }
    },

    mounted () {
        console.log(this.$refs.oaGroup)
    },
    computed: {
        ...mapGetters(['currentUser']),

        ownedDeptIds () {
            let arr = []
            arr.push(this.searchData.groupId)
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    arr.push(item.groupId)
                })
            }
            arr = [...new Set(arr)]
            // console.log(arr)
            return arr
        }
    },
    methods: {
        filterDept (val) {
            let str = ''
            str = this.$refs.oaGroup.list.filter(
                (item) => Number(item.id) === Number(val)
            )[0].groupName
            return str
        },
        syncStatus (row, column, value, index) {
            return value ? '是' : '否'
        },
        date (row, column, value, index) {
            return value ? value.substr(0, 10) : ''
        },
        showPassword (item) {
            this.$refs['thePassword'].show(item)
        },
        search () {
            this.changePage(1)
        },
        searchNine () {
            this.changePageNine(1)
        },
        changePage (value) {
            this.searchData.current = value
            this.fetchData()
        },
        changePageNine (value) {
            this.searchData.current = value
            this.kpiestimatePage()
        },
        handleClick (tab, event) {
            console.log(tab, event)
            console.log(JSON.stringify(tab.name))
            if (tab.name === 'second') {
                this.kpiestimatePage()
            } else {
                this.searchData = {}
                this.searchData.current = 1
                this.searchData.size = 10
                this.fetchData()
            }
        },
        async deleteEstimate (val) {
            let deleteItem = { id: val }
            const res = await ehr.deleteEstimate(deleteItem)
        },
        reset () {
            this.$refs['searchForm'].resetFields()
            this.searchData.userName = ''
            this.searchData.displayName = ''
            this.searchData.groupId = null
            this.searchData.employeeId = null
            this.$nextTick(() => {
                const oaGroupRef = this.$refs.oaGroup
                oaGroupRef.cascaderValue = null
            })
            this.$refs.employee2.currentItem = {}
            this.$refs.employee2.value = null
            this.changePage(1)
        },

        resetNine () {
            this.$refs['searchFormNine'].resetFields()
            this.searchData.userName = ''
            this.searchData.displayName = ''
            this.searchData.employeeId = null
            this.searchData.estimatorId = null
            this.searchData.status = null
            this.searchData.dateStart = null
            this.searchData.dateEnd = null
            this.dateRange = []
            this.$nextTick(() => {
                const oaGroupRef = this.$refs.dept
                oaGroupRef.cascaderValue = null
            })

            this.searchData.groupId = null
            this.$refs.employee.currentItem = {}
            this.$refs.employee.value = null
            this.$refs.searchFormNine.title = ''
            this.changePageNine(1)
        },

        dateChange (val) {
            if (val && val.length > 0) {
                this.searchData.dateStart = val[0]
                this.searchData.dateEnd = val[1]
            }
        },
        async kpiestimatePage () {
            let that = this
            const res = await ehr.kpiestimatePage(this.searchData)
            console.log('hahah' + JSON.stringify(res))
            that.listData = res.data
            that.dataCount = res.total
        },

        handleDeptChange (val) {
            this.bussinessDepts = []
            let flag = true
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    if (item.isAdmin && flag) {
                        item.groupId = val.id
                        item.groupName = val.groupName
                        flag = false
                    } else {
                        item.isAdmin = false
                    }
                })
            } else {
                this.bussinessDepts.push({
                    groupId: val.id,
                    groupName: val.groupName,
                    isAdmin: true,
                    isPrimary: true,
                    isOwn: false
                })
            }
        },
        getDetails (row, index) {
            let pageSize = this.searchData.size
            let pageIndex = this.searchData.current
            console.log('pageSize:' + pageSize)
            console.log('pageIndex:' + pageIndex)
            let id = row.id
            this.$router.push({
                path: '/oa/estimate-detail',
                query: { id, pageSize: pageSize, pageIndex: pageIndex }
            })
        },
        getDetailsNine (row) {
            let id = row.id
            this.$router.push({
                path: '/oa/estimate-detail',
                query: { id, type: '9' }
            })
        },
        async fetchData () {
            let that = this
            const res = await ehr.estimateList(this.searchData)
            that.listData = res.data
            that.dataCount = res.total
        },
        cancel () {
            this.$router.push({
                path: '/oa/estimate'
            })
        },
        editItem (item) {
            this.editVisible = true
            let form = this.$refs['itemForm']
            if (form) {
                form.resetFields()
            }
            this.currentItem = _.clone(item)
            this.editTitle = item.userId ? '编辑用户信息' : '新增用户'
        },
        editForm () {
            let that = this
            this.$refs['itemForm'].validate((valid) => {
                if (!valid) {
                    return false
                }
                let item = that.currentItem
                axios
                    .fetch(
                        'userServer',
                        item.userId ? '/permission/updateUser' : '/permission/createUser',
                        item
                    )
                    .then((res) => {
                        this.editVisible = false
                        that.$message.success('操作成功')
                        that.fetchData()
                    })
            })
        },

        removeItemNine (item) {
            this.$confirm('是否确定终止考核！！！')
                .then(() => {
                    let deleteData = { id: item.id }
                    this.deleteForNine(deleteData)
                })
                .catch(() => {})
        },
        toTemplate () {
            this.$router.push({
                path: '/oa/assessTemplate'
            })
        },

        removeItem (item) {
            let deleteData = { id: item.id }
            this.$confirm('是否确定终止考核！！！')
                .then(() => {
                    this.delete(deleteData)
                })
                .catch(() => {})
        },
        accountItem (item) {
            this.$router.push({
                path: 'account',
                query: { userId: item.userId }
            })
        },

        async deleteForNine (data) {
            await ehr.deleteForNine(data)
            this.kpiestimatePage()
        },
        async delete (data) {
            await ehr.deleteEstimate(data)
            this.fetchData()
        },
        employeeChange (item) {
            if (item) {
                this.currentItem.realName = item.employeeName
                this.currentItem.displayName = item.employeeName
            }
        }
    },
    filters: {
        dictionaryFilter: gbDictionary.filter
    }
}
</script>
