<template>
    <div class="content">
        <div>
            <el-form
                ref="searchFormNine"
                :inline="true"
                class="page-search"
                :model="searchData"
            >
                <div class="left">
                    <el-form-item label="">
                        <el-input
                            v-model="searchData.groupName"
                            placeholder="组织名称"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchData.groupNo"
                            placeholder="组织编码"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <gb-employee
                            ref="employee"
                            v-model="searchData.employeeId"
                            clearable
                            placeholder="负责人"
                            api-name="getEmlpoyeesList"
                            :is-cascader="false"
                            :value-filed="'employeeId'"
                            :label-filed="'employeeName'"
                        ></gb-employee>
                    </el-form-item>
                </div>
                <div class="right">
                    <div class="search-btn">
                        <div class="custom-btn">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handSearch"
                            >
                                查询
                            </el-button>
                            <el-button @click="resetNine">重置</el-button>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="editItem({ enabled: true })">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    >添加组织</i>
                </span>
                <span @click="changeExpand">
                    <i class="el-icon-document-copy">{{ expandText }}</i>
                </span>
                <!-- <span @click="$router.push('job')">
                    <i class="el-icon-s-promotion">岗位管理</i>
                </span> -->
            </div>
            <div class="gb-table">
                <el-table
                    ref="table"
                    :data="tableData"
                    border
                    height="500"
                    row-key="id"
                    :indent="32"
                    :default-expand-all="expandAll"
                    :tree-props="{
                        children: 'children',
                        hasChildren: 'hasChildren'
                    }"
                >
                    <!-- <el-table-column
                        label="序号"
                        type="index"
                        min-width="55"
                    ></el-table-column>
                    <el-table-column
                        prop="id"
                        label="组织ID"
                        min-width="55"
                        align="center"
                    ></el-table-column> -->
                    <el-table-column
                        prop="groupName"
                        label="组织名称"
                        min-width="100"
                    ></el-table-column>
                    <el-table-column prop="groupNo" label="业务组织编码">
                        <template slot-scope="scope">
                            <span>{{ scope.row.groupNo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="enabled"
                        label="状态"
                        :formatter="status"
                    ></el-table-column>
                    <el-table-column
                        prop="employeeName"
                        label="组织负责人"
                    ></el-table-column>
                    <el-table-column
                        prop="updateTime"
                        label="更新时间"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="mini"
                                @click="editItem(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                v-if="scope.row.enabled == '1' "
                                type="text"
                                size="mini"
                                @click="removeItem(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog
            :title="editTitle"
            :visible.sync="editVisible"
            width="500px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="itemForm"
                :model="currentItem"
                label-position="left"
                status-icon
                label-width="80px"
            >
                <el-form-item
                    label="组织名称"
                    prop="groupName"
                    :show-message="false"
                    required
                >
                    <el-input
                        v-model.trim="currentItem.groupName"
                        clearable
                        maxlength="15"
                        show-word-limit
                        type="text"
                        placeholder="组织名称"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="组织类型" :show-message="false">
                    <el-select
                        v-model="currentItem.deptNo"
                        placeholder="请选择岗位类型"
                    >
                        <el-option
                            v-for="item in deptNoData"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="上级组织" prop="parentId">
                    <gb-department
                        ref="dept"
                        v-model="currentItem.parentId"
                        style="width: 100%;"
                        placeholder="请选择上级组织"
                        value-filed="id"
                        expand-trigger="click"
                        label-filed="groupName"
                    >
                    </gb-department>
                </el-form-item>

                <el-form-item label="负责人" prop="employeeId">
                    <el-select

                        v-model="currentItem.employeeId"
                        filterable
                        clearable
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in employeeList"
                            :key="item.employeeId"
                            :label="item.employeeName"
                            :value="item.employeeId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item
                            label="是否有效"
                            prop="enabled"
                            :show-message="false"
                            required
                        >
                            <el-switch
                                v-model="currentItem.enabled"
                                :active-value="true"
                                :inactive-value="false"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item
                            label="是否同步"
                            prop="syncStatus"
                            :show-message="false"
                            required
                        >
                            <el-switch
                                v-model="currentItem.syncStatus"
                                :active-value="1"
                                :inactive-value="0"
                            ></el-switch>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="editForm()">确 定</el-button>
                <el-button @click="editVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import utilsTable from '@/utils/table'
import { makeDataToTree } from '@/utils/index'
import oaSvc from '@/services/oa'
import gbDepartment from '@/components/Department'
import gbEmployee from '@/components/Department'
import breadSlot from './components/groupBreadSlot'
import BreadSlotMixin from 'mixins/breadSlot'
const breadSlotMixin = new BreadSlotMixin({ breadSlot })
var that

export default {
    name: 'GroupName',
    components: {
        gbDepartment,
        gbEmployee
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            fullscreenLoading: false,
            searchData: {
                groupName: null,
                groupNo: null,
                employeeId: null
            },
            expandText: '全部展开',
            expandAll: false,
            listData: [],
            tableData: [],
            deptList: [],
            currentItem: {
                deptNo: '',
                employeeId: null
            },
            employeeList: [],
            editVisible: false,
            editTitle: '',
            search: '上海'
        }
    },
    watch: {
        editVisible (newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.$refs.dept.getData()
                })
            }
        },
        expandAll (newValue) {
            this.forArr(this.tableData, newValue)
        }
    },
    activated () {
        this.addSlot(breadSlot)
    },
    created () {
        this.fetchData()
    },
    methods: {
        ...utilsTable,
        async fetchData () {
            const { data = [] } = await oaSvc.getAllDepts()
            this.deptList = data
            this.tableData = makeDataToTree(data, 'parentId', 'id')
        },
        handSearch () {
            this.tableData = this.deptList
            // this.xmgcqkJsonsData：用于搜索的总数据
            //　toLowerCase():用于把字符串转为小写，让模糊查询更加清晰
            let groupName = null
            let groupNo = null
            let employeeId = null
            console.log('sss:' + JSON.stringify(this.searchData))
            if (!this.searchData.groupName && !this.searchData.groupNo && !this.searchData.employeeId) {
                this.tableData = makeDataToTree(this.deptList, 'parentId', 'id')
                return
            }
            if (this.searchData.groupName) {
                groupName = this.searchData.groupName.toLowerCase()
            }
            if (this.searchData.groupNo) {
                groupNo = this.searchData.groupNo.toLowerCase()
            }
            if (this.searchData.employeeId) {
                employeeId = this.searchData.employeeId.toLowerCase()
            }

            let newListData = [] // 用于存放搜索出来数据的新数组
            if (groupName) {
                this.deptList.filter(item => {
                    if (item.groupName.toLowerCase().indexOf(groupName) !== -1) {
                        newListData.push(item)
                    }
                })
                console.log(newListData.length)
                if (newListData.length == 0) {
                    this.tableData = []
                    return
                }
            }
            if (groupNo) {
                if (newListData.length > 0) {
                    let newNoListData = []
                    newListData.filter(item => {
                        if (item.groupNo.toLowerCase().indexOf(groupNo) !== -1) {
                            newNoListData.push(item)
                        }
                    })
                    newListData = newNoListData
                } else {
                    this.deptList.filter(item => {
                        if (item.groupNo.toLowerCase().indexOf(groupNo) !== -1) {
                            newListData.push(item)
                        }
                    })
                }
                if (!(newListData.length > 0)) {
                    this.tableData = []
                    return
                }
            }
            if (employeeId) {
                if (newListData.length > 0) {
                    let newIdListData = []
                    newListData.filter(item => {
                        if (item.employeeId && item.employeeId == employeeId) {
                            newIdListData.push(item)
                        }
                    })
                    newListData = newIdListData
                } else {
                    this.deptList.filter(item => {
                        if (item.employeeId && item.employeeId == employeeId) {
                            newListData.push(item)
                        }
                    })
                }
            }
            this.tableData = newListData
            // console.log(‘新数组',newListData)

            // if (this.searchData.groupName) {
            //     console.log(this.searchData.groupName)
            // }
        },
        // 列表展开和收起
        forArr (arr, isExpand) {
            arr.forEach(i => {
                this.$refs.table.toggleRowExpansion(i, isExpand)
                if (i.children) {
                    this.forArr(i.children, isExpand)
                }
            })
        },
        changeExpand () {
            this.expandAll = !this.expandAll
            this.expandText = this.expandAll ? '全部收缩' : '全部展开'
            // this.expandRowKeys = this.tableData.map(item => item.id + '')
        },
        editItem (item) {
            this.editVisible = true
            if (item.id) {
                this.$nextTick(() => {
                    this.$refs.dept.cascaderValue = item.parentId
                })
            } else {
                this.$nextTick(() => {
                    this.$refs.dept.cascaderValue = ''
                })
            }
            let form = this.$refs['itemForm']
            if (form) {
                form.resetFields()
            }
            this.getEmlpoyeesList()
            this.currentItem = _.clone(item)
            this.editTitle = item.id ? '编辑组织信息' : '新增组织'
        },
        // 获取全部员工信息
        async getEmlpoyeesList () {
            // let employeeQuery = { status: 1 }
            let employeeQuery = { selectStatus: [0, 1] }
            const { data = [] } = await oaSvc.getEmlpoyeesList(employeeQuery)
            this.employeeList = data
        },
        editForm () {
            let that = this

            this.$refs['itemForm'].validate(async valid => {
                if (!valid) {
                    return false
                }
                try {
                    // this.fullscreenLoading = true
                    that.currentItem.companyId = 0
                    if (this.currentItem.id) {
                        await oaSvc.editDept(that.currentItem)
                    } else {
                        await oaSvc.addDept(that.currentItem)
                    }
                    this.resetNine()
                    this.editVisible = false
                    this.fullscreenLoading = false
                    that.$message.success('操作成功')
                    that.fetchData()
                } catch (error) {
                    this.fullscreenLoading = false
                    console.log(error)
                }
            })
        },
        resetNine () {
            this.$refs['searchFormNine'].resetFields()
            this.searchData.groupName = null
            this.searchData.groupNo = null
            this.searchData.employeeId = null
            this.expandAll = false
            // this.changeExpand()
            // this.tableData = this.deptList
            this.tableData = makeDataToTree(this.deptList, 'parentId', 'id')
            this.$refs.employee.currentItem = {}
            this.$refs.employee.value = null
        },
        removeItem (item) {
            let that = this
            this.$confirm('确认删除吗？')
                .then(async () => {
                    try {
                        await oaSvc.delDept(item)
                        that.$message.success('删除成功')
                        that.fetchData()
                    } catch (error) {
                        console.log(error)
                    }
                })
                .catch(() => {})
        }
    },
    beforeCreate () {
        that = this
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-form {
    .el-select,
    .el-cascader {
        width: 100%;
    }
}
</style>
