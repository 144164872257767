<template>
    <el-steps :active="active" finish-status="success" class="custom-steps">
        <el-step v-for="(item, i) in steps" :key="i" :title="item"></el-step>
    </el-steps>
</template>
<script>
export default {
    name: 'steps-bar',
    props: {
        // 步骤第一步为0以此类推
        active: {
            type: Number,
            default: 0
        },
        // groups [...步骤名字集合]
        groups: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        steps() {
            // todo:是否克隆待定
            return this.groups
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-steps {
    ::v-deep {
        .el-step__head.is-process, .el-step__head.is-success {
            color: #3595E1;
            border-color: #3595E1
        }
        .el-step__title.is-process, .el-step__title.is-success {
            color: #3595E1;
        }

        .el-step.is-horizontal .el-step__line {
            top: 15px;
        }
    }
}
</style>
