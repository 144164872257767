<template>
    <div>
        <el-form :inline="true" class="page-search" :model="searchData">
            <div class="left">
                <el-form-item label="">
                    <el-select v-model="value" placeholder="请选择类型">
                        <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="">
                    <el-input
                        v-model="searchData.employeeNameLike"
                        placeholder="名称"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="search"
                        >
                            查询
                        </el-button>
                        <el-button
                            icon="el-icon-refresh-right"
                            @click="reset"
                        >
                            重置
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <!-- <span @click="add()"> -->
                <span @click="add()">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang">新增</i>
                </span>
                <span
                    v-if="searchData.selectStatus.includes(1)"
                    @click="searchByStatus([-1])"
                >
                    <i class="el-icon-s-promotion">离职员工</i>
                </span>
                <span
                    v-if="searchData.selectStatus.includes(-1)"
                    @click="searchByStatus([0, 1])"
                >
                    <i class="el-icon-s-promotion">在职员工</i>
                </span>
            </div>
            <div class="gb-table">
                <el-table :data="listData" height="500">
                    <el-table-column min-width="50" label="序号" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                (searchData.current - 1) * searchData.size + scope.$index + 1
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="employeeName"
                        label="类型"
                        min-width="50"
                    ></el-table-column>
                    <el-table-column
                        prop="gender"
                        label="名称"
                        min-width="25"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="jobNumber"
                        label="评分说明"
                        min-width="100"
                        align="center"
                    ></el-table-column>

                    <el-table-column
                        prop="mobilePhone"
                        label="得分范围"
                        min-width="100"
                    ></el-table-column>
                    <el-table-column
                        prop="joinDate"
                        label="考核人"
                        :formatter="date"
                        min-width="50"
                    ></el-table-column>
                    <el-table-column
                        prop="joinDate"
                        label="排序"
                        :formatter="date"
                        min-width="50"
                    ></el-table-column>
                    <el-table-column
                        v-if="searchData.selectStatus == -1"
                        prop="dimissionDate"
                        label="离职日期"
                        :formatter="date"
                        min-width="50"
                    ></el-table-column>
                    <el-table-column label="操作" align="center" min-width="100">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.status !== -1"
                                size="mini"
                                type="text"
                                @click="dismisson(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                v-if="permissionPassword && scope.row.status !== -1"
                                size="mini"
                                type="text"
                                @click="showPassword(scope.row)"
                            >
                                删除
                            </el-button>
                            <!-- <el-button
                                v-if="
                                    permissionWechat && scope.row.status !== -1
                                "
                                @click="showWechat(scope.row)"
                                type="text"
                                size="mini"
                                >绑定微信</el-button
                            > -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :total="dataCount"
                    :current-page="searchData.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchData.size"
                    layout="total, sizes,prev, pager, next, jumper"
                    @current-change="changePage"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>

        <!--新增考核信息-->

        <el-dialog
            title="新增考核指标"
            :visible.sync="showCreateOrUpdate"
            width="600px"
            center
            top="20vh"
            :modal="true"
        >
            <el-row class="warp">
                <el-col style="margin-top: 20px;">
                    <el-form ref="quitForm" :model="quitForm" :rules="quitRules">
                        <el-row :gutter="20">
                            <el-col id="quitDiags" :span="7" class="center">
                                <img
                                    v-if="staffForm.employeePhoto"
                                    id="quitDiagsImg"
                                    :src="staffForm.employeePhoto"
                                    alt
                                />
                                <img
                                    v-if="!staffForm.employeePhoto"
                                    id="quitDiagsImg"
                                    src="//image.youbankeji.com/web/dahuaAdmin/Group 4@2x.png"
                                    alt
                                    class="cardId"
                                />
                                <h5>{{ staffForm.employeeName }}</h5>
                                <h6>{{ staffForm.jobName }}</h6>
                            </el-col>
                            <el-col :span="17">
                                <el-row :gutter="0">
                                    <el-form-item label="离职日期：" prop="dimissionDate">
                                        <el-date-picker
                                            v-model="quitForm.dimissionDateString"
                                            type="date"
                                            placeholder="选择日期"
                                            format="yyyy 年 MM 月 dd 日"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-form-item label="离职原因：" prop="dimissionRemark">
                                        <el-input
                                            v-model="quitForm.dimissionRemark"
                                            type="textarea"
                                            placeholder="请输入离职原因"
                                            rows="6"
                                        ></el-input>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-col span="24" class="center">
                                        <el-button
                                            type="primary"
                                            @click="submitDismisson()"
                                        >
                                            提交
                                        </el-button>
                                        <el-button @click="visibleQuit = false">关闭</el-button>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
        </el-dialog>

        <gb-wechat ref="wechat"></gb-wechat>
        <gb-password ref="thePassword" modify-type="admin"></gb-password>
    </div>
</template>

<script>
import permission from '@/utils/permission'
import axios from 'axios'
import utilsTable from '@/utils/table'
import gbWechat from '@/components/wechat-binding'
import gbPassword from '@/components/password'
import oaSvc from '@/services/oa'
import department from '@/components/Department'
import ehr from '@/services/ehr'

export default {
    name: 'EmployeeFile',
    components: { gbPassword, department, gbWechat },
    data () {
        let that = this
        return {
            permissionPassword: false,
            permissionWechat: false,
            visibleQuit: false,
            showCreateOrUpdate: false,
            listData: [],
            dataCount: 0,
            staffForm: {},
            quitForm: {},
            value: '',
            options: [
                '业务指标',
                '行为指标',
                '附加项'
            ],
            searchData: {
                employeeNameLike: null,
                joinDateFrom: '',
                joinDateTo: '',
                groupId: null,
                selectStatus: [0, 1],
                size: 20,
                current: 1
            },
            registerPlaces: [],
            quitRules: {
                dimissionDateString: [
                    {
                        required: true,
                        message: '请输入离职日期',
                        trigger: ['blur', 'change']
                    }
                ],
                dimissionRemark: [
                    {
                        required: true,
                        message: '请输入离职原因',
                        trigger: ['blur', 'change']
                    }
                ]
            }
        }
    },
    computed: {
        dateValues: {
            get () {
                return [this.searchData.joinDateFrom, this.searchData.joinDateTo]
            },
            set (value) {
                this.searchData.joinDateFrom = value ? value[0] : ''
                this.searchData.joinDateTo = value ? value[1] : ''
            }
        }
    },
    mounted () {
        let data = {
            parentNos: '户籍类型'
        }
        axios
            .fetch('commonServer', '/dict/getItemViewsByParentNo', data)
            .then((res) => {
                this.registerPlaces = res.data
            })
    },
    created () {
        this.permissionPassword = permission.hasPermission(
            'gbei.oa.employee.password'
        )
        this.permissionWechat = permission.hasPermission('gbei.oa.employee.wechat')
        this.fetchData()
    },
    methods: {
        ...utilsTable,
        registerPlaceFormatter (row, column, value, index) {
            let objects = this.registerPlaces
            for (let key of Object.keys(objects)) {
                if (objects[key].itemValue == value) {
                    return objects[key].itemName
                }
            }
        },
        search () {
            this.changePage(1)
        },
        searchByStatus (selectStatus) {
            this.searchData.selectStatus = selectStatus
            this.changePage(1)
        },
        reset () {
            this.searchData.employeeNameLike = null
            this.searchData.joinDateFrom = ''
            this.searchData.joinDateTo = ''
            this.searchData.groupId = ''
            this.$refs.dept.cascaderValue = ''
            this.changePage(1)
        },
        changePage (value) {
            this.searchData.current = value
            this.fetchData()
        },
        handleSizeChange (val) {
            this.searchData.size = val
            this.fetchData()
        },
        async fetchData () {
            const res = await ehr.getAssessPage(this.searchData)
            this.listData = res.data || []
            this.dataCount = res.total
        },
        showPassword (item) {
            let thePassword = this.$refs['thePassword']
            item.displayName = item.employeeName
            thePassword.show(item)
        },
        edit (data) {
            this.$store.commit('setShowParent', 1)
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee/edit?currentTab=edit&sign=edit',
                query: {
                    employeeId: employeeId
                }
            })
        },
        add () {
            //   this.$router.push({
            //     path: "/oa/employee-add",
            //   })
            this.showCreateOrUpdate = true
        },
        seeDetail (data) {
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee-detail',
                query: { employeeId }
            })
        },
        dismisson (data) {
            let theForm = this.$refs['quitForm']
            if (theForm) {
                theForm.resetFields()
            }
            this.quitForm = {}
            this.staffForm = data
            this.visibleQuit = true
        },
        submitDismisson () {
            this.quitForm.employeeId = this.staffForm.employeeId
            this.$refs['quitForm'].validate(async (valid) => {
                if (!valid) {
                    return false
                } else {
                    await oaSvc.leaveOffice(this.quitForm)
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                    this.visibleQuit = false
                    this.fetchData()
                }
            })
        },
        datasync () {
            this.$axios.fetch('datasyncServer', '/district/cityData').then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.data)
                } else {
                    this.$message.error(res.message)
                }
            })
            this.$axios.fetch('datasyncServer', '/district/employee').then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.data)
                    this.initData()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        showWechat (item) {
            this.$refs['wechat'].showQrCode(item.employeeId, item.employeeName)
        }
    }
}
</script>

<style lang="less" scoped>
.name-tips {
    margin-left: 30px;
}

.error-tips {
    font-size: 12px;
    line-height: 1;
    color: #f56c6c;
}

#quitDiagsImg {
    width: 150px !important;
    height: 197px !important;
}

.el-textarea {
    display: inline-block;
    width: 220px;
}

.center {
    text-align: center;
}
</style>
