<template>
    <div>
        <el-form :inline="true" class="page-search" :model="searchData">
            <div class="left">
                <el-form-item label="">
                    <gb-dictionary
                        v-show="isEdit"
                        v-model="searchData.templateType"
                        category="searchData.templateType"
                        group="KPI考核模板类型"
                        placeholder="请选择类型"
                        clearable
                    ></gb-dictionary>
                </el-form-item>

                <el-form-item label="">
                    <el-input
                        v-model="searchData.title"
                        placeholder="名称"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="创建开始日期"
                        end-placeholder="创建结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="true"
                        @change="dateChange"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="search"
                        >
                            查询
                        </el-button>
                        <el-button
                            icon="el-icon-refresh-right"
                            @click="reset"
                        >
                            重置
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <!-- <span @click="add()"> -->
                <span @click="add()">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang">新增</i>
                </span>

                <!-- <span @click="toIndicators()">
          <i class="el-icon-s-promotion">考核指标</i>
        </span> -->
            </div>
            <div class="gb-table">
                <el-table v-loading="isLoading" :data="listData" height="500">
                    <el-table-column min-width="50" label="序号" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                (searchData.current - 1) * searchData.size + scope.$index + 1
                            }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="indicatorType" label="类型" min-width="50">
                        <template slot-scope="scope">
                            　<span>
                                {{
                                    scope.row.templateType
                                        | dictionaryFilter(vueInstance, "KPI考核模板类型")
                                }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="title"
                        label="名称"
                        min-width="50"
                        maxlength="20"
                        show-word-limit
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="description"
                        label="考核说明"
                        min-width="100"
                        maxlength="200"
                        show-word-limit
                        align="center"
                    ></el-table-column>

                    <el-table-column
                        prop="createTime"
                        label="创建时间"
                        min-width="100"
                        align="center"
                    ></el-table-column>

                    <el-table-column label="操作" align="center" min-width="100">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                @click="launch(scope.row)"
                            >
                                发起考核
                            </el-button>

                            <el-button
                                size="mini"
                                type="text"
                                @click="dismisson(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                @click="deleteItem(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :total="dataCount"
                    :current-page="searchData.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchData.size"
                    layout="total, sizes,prev, pager, next, jumper"
                    @current-change="changePage"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>

        <!--新增考核模板信息-->

        <el-dialog
            :title="operation ? '新增考核模板' : '编辑考核模板'"
            :visible.sync="showCreateOrUpdate"
            width="800px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="indicatorsForm"
                :rules="quitRules"
                :model="indicatorsForm"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="templateType">
                            <gb-dictionary
                                v-model="indicatorsForm.templateType"
                                category="indicatorsForm.templateType"
                                :dict-bus="vueInstance"
                                disabled
                                group="KPI考核模板类型"
                            ></gb-dictionary>
                        </el-form-item>

                        <el-form-item label="名称" prop="title">
                            <el-input
                                v-model="indicatorsForm.title"
                                placeholder="请输入指标名称"
                                style="width: 50%; display: block"
                                size="small"
                            ></el-input>
                        </el-form-item>

                        <el-form
                            ref="templateEmployee"
                            :rules="quitRules"
                            :model="templateEmployee"
                            label-width="80px"
                        >
                        </el-form>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="考核说明">
                            <el-input
                                v-model="indicatorsForm.description"
                                type="textarea"
                                :autosize="{ minRows: 4, maxRows: 10 }"
                                style="width: 80%; display: block"
                                size="small"
                                placeholder="请输入评分说明"
                                maxlength="200"
                                show-word-limit
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider></el-divider>

                <span>添加指标</span>

                <div style="margin-top: 30px">
                    <el-row>
                        <el-col :span="6">
                            <!-- <el-button @click="addIndicator" size="mini" type="text"
                >添加</el-button
              > -->
                        </el-col>
                        <el-col :span="18">
                            <el-select
                                v-model="templateIndicator"
                                multiple
                                filterable
                                remote
                                value-key="title"
                                reserve-keyword
                                placeholder="请输入关键词"
                                :remote-method="getIndicatorsPage"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in indicators"
                                    :key="item.title"
                                    :label="item.title"
                                    :value="item"
                                />
                            </el-select>
                        </el-col>
                    </el-row>
                </div>

                <el-divider></el-divider>

                <el-table
                    v-loading="isLoading"
                    :data="templateIndicator"
                    max-height="250"
                >
                    <el-table-column min-width="50" label="序号" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                (searchData.current - 1) * searchData.size + scope.$index + 1
                            }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="indicatorType" label="类型" min-width="50">
                        <template slot-scope="scope">
                            　<span>
                                {{
                                    scope.row.indicatorType
                                        | dictionaryFilter(vueInstance, "KPI考核指标类型")
                                }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="title"
                        label="名称"
                        min-width="50"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="description"
                        label="评分说明"
                        min-width="100"
                        align="center"
                    ></el-table-column>
                    <el-table-column prop="scoreStart" label="得分范围" min-width="100">
                        　　<template slot-scope="scope">
                            　<span>
                                {{ scope.row.scoreStart }} ~{{ scope.row.scoreEnd }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="dispIndex"
                        label="排序"
                        min-width="50"
                    ></el-table-column>

                    <el-table-column label="操作" align="center" min-width="100">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                @click="deleteIndicatorItem(scope.$index)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitDismisson">确 定</el-button>
                <el-button @click="showCreateOrUpdate = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="发起考核"
            :visible.sync="showLaunch"
            width="600px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="launchForm"
                :rules="launchRules"
                :model="launchForm"
                label-width="80px"
            >
                <!-- <el-form-item prop="dateType">
            <el-radio-group v-model="dateType" @change="changeDataType">
          <el-radio  label="3">年度</el-radio>
          <el-radio  label="2">季度</el-radio>
          <el-radio  label="1">月度</el-radio>
  </el-radio-group>
        </el-form-item> -->

                <el-form-item label="考核名称" prop="title">
                    <el-input
                        v-model="launchForm.title"
                        style="width: 60%; display: block"
                        size="small"
                        placeholder="请输入考核名称"
                        maxlength="20"
                        show-word-limit
                    ></el-input>
                </el-form-item>

                <el-form-item label="考核时间" prop="dateRangelaunch">
                    <el-date-picker
                        v-model="launchForm.dateRangelaunch"
                        type="monthrange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        value-format="yyyy-MM"
                        @change="dateChangeLaunch"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="组织" required>
                    <el-row>
                        <el-col :span="12">
                            <gb-department
                                v-show="isEdit"
                                ref="oaGroup"
                                v-model="launchForm.groupId"
                                class="oaGroup"
                                placeholder="请选择组织"
                                api-name="getAllDepts"
                                :disabled-ids="ownedDeptIds"
                                :is-cascader="true"
                                :show-all-levels="false"
                                value-filed="id"
                                :expand-trigger="'click'"
                                label-filed="groupName"
                                @change="handleDeptChange"
                            >
                            </gb-department>
                            <span v-if="!isEdit">{{ employeeInfo.groupName }}</span>
                        </el-col>
                        <el-col :span="12">
                            <el-radio v-model="radio" label="1" @click.native.prevent="hasChild('1')">是否包含子组织</el-radio>
                        </el-col>
                    </el-row>
                </el-form-item>

                <el-form-item label="岗位类型" :show-message="false" prop="categoryId">
                    <el-select
                        v-model="launchForm.jobCategoryIds"
                        placeholder="请选择岗位类型"
                        multiple
                    >
                        <el-option
                            v-for="item in jobcategoryList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-input
                        v-model="launchForm.remark"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 10 }"
                        style="width: 80%; display: block"
                        size="small"
                        placeholder="请输入评分说明"
                        maxlength="200"
                        show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitLaunch">确 定</el-button>
                <el-button @click="showLaunch = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import utilsTable from '@/utils/table'
import gbDepartment from '@/components/Department'
import ehr from '@/services/ehr'
import gbDictionary from '@/components/dictionary'
import breadSlot from '../components/breadSlot'
import BreadSlotMixin from 'mixins/breadSlot'
import oaSvc from '@/services/oa' // 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'EmployeeFile',
    components: { gbDepartment, gbDictionary },
    filters: {
        filterStatus: function (value) {
            return value ? '是' : '否'
        },
        dictionaryFilter: gbDictionary.filter
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            vueInstance: this,
            visibleQuit: false,
            showCreateOrUpdate: false,
            listData: [],
            listindicatorData: [],
            bussinessDepts: [], // 业务组织列表数据
            dataCount: 0,
            staffForm: {},
            radio: '1',
            showLaunch: false,
            isEdit: true,
            quitForm: {},
            jobcategoryList: [],
            dateType: '3',
            dateRange: [],
            dateRangelaunch: [],
            indicators: [],
            indicatorsForm: {},
            templateForm: {},
            launchForm: {
                hasChild: '1'
            },
            templateEmployee: {},
            templateIndicator: [],
            searchIndicatorsData: {
                size: 100,
                current: 1
            },
            operation: true, // true新增，fase编辑
            isLoading: false,
            value: '',
            searchData: {
                employeeNameLike: null,
                joinDateFrom: '',
                joinDateTo: '',
                groupId: null,
                selectStatus: [0, 1],
                size: 20,
                current: 1
            },

            launchRules: {
                title: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: ['blur', 'change']
                    }
                ],
                dateRangelaunch: [
                    {
                        required: true,
                        message: '请选择时间',
                        trigger: ['blur', 'change']
                    }
                ]
            },

            quitRules: {
                indicatorType: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: ['blur', 'change']
                    }
                ],
                dateType: [
                    {
                        required: true,
                        message: '请输入日期类型',
                        trigger: ['blur', 'change']
                    }
                ],
                scoreStart: [
                    {
                        required: true,
                        message: '请输入范围',
                        trigger: ['blur', 'change']
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: ['blur', 'change']
                    }
                ]

                // groupId: [
                //   {
                //     required: true,
                //     message: "请选择组织",
                //     trigger: ["blur", "change"],
                //   },
                // ],
            }
        }
    },
    computed: {
        startTime () {
            return this.$moment(this.launchForm.dateRangelaunch[0]).startOf('month').format('YYYY-MM-DD')
        },
        endTime () {
            return this.$moment(this.launchForm.dateRangelaunch[1]).endOf('month').format('YYYY-MM-DD')
        },
        ownedDeptIds () {
            let arr = []
            arr.push(this.templateEmployee.groupId)
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    arr.push(item.groupId)
                })
            }
            arr = [...new Set(arr)]
            // console.log(arr)
            return arr
        }
    },
    mounted () {},
    created () {
        gbDictionary.init(this.vueInstance, ['KPI考核指标类型', 'KPI考核模板类型'])
        this.fetchData()
    },
    activated () {
        this.addSlot(breadSlot)
    },
    methods: {
        ...utilsTable,
        registerPlaceFormatter (row, column, value, index) {
            let objects = this.registerPlaces
            for (let key of Object.keys(objects)) {
                if (objects[key].itemValue == value) {
                    return objects[key].itemName
                }
            }
        },
        toIndicators () {
            this.$router.push({
                path: '/oa/indicators'
            })
        },
        hasChild (e) {
            if (e === this.radio) {
                this.radio = 0
            } else {
                this.radio = e
            }
            this.launchForm.hasChild = this.radio
        },
        changeDataType (val) {
            this.launchForm.dateType = val
        },
        search () {
            this.changePage(1)
        },
        dateChange (val) {
            if (val && val.length > 0) {
                this.searchData.startDate = val[0]
                this.searchData.endDate = val[1]
            }
        },
        dateChangeLaunch (val) {
            if (val && val.length > 0) {
                this.launchForm.dateStart = val[0]
                this.launchForm.dateEnd = val[1]
            }
        },
        reset () {
            this.searchData = { size: 20,
                current: 1 }
            this.dateRange = []
            this.changePage(1)
        },
        changePage (value) {
            this.searchData.current = value
            this.fetchData()
        },
        launch (row) {
            console.log('开始发起考核' + JSON.stringify(row))
            this.launchForm = {}
            this.$set(this.launchForm, 'title', row.title)
            this.launchForm.templateId = row.id
            this.dateType = '3'
            this.getJobCategoryList()
            this.dateRangelaunch = []
            this.launchForm.dateType = '3'
            this.launchForm.hasChild = '1'
            this.showLaunch = true
        },
        handleSizeChange (val) {
            this.searchData.size = val
            this.fetchData()
        },
        async getJobCategoryList () {
            const { data = [] } = await oaSvc.jobcategoryList()
            this.jobcategoryList = data
        },
        async fetchData () {
            this.isLoading = true
            const res = await ehr.getTemplatePage(this.searchData)
            this.listData = res.data || []
            this.dataCount = res.total
            this.isLoading = false
        },
        async submitLaunch () {
            this.$refs['launchForm'].validate(async (valid) => {
                if (!valid) {
                    return false
                } else {
                    console.log('提交数据 : ' + JSON.stringify(this.launchForm))
                    this.launchForm.dateStart = this.startTime
                    this.launchForm.dateEnd = this.endTime
                    const res = await ehr.launchAdd(this.launchForm)
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                    this.showLaunch = false
                }
            })
        },
        async getTemplateInfo (id) {
            let tempInfo = { id: id }
            this.isLoading = true
            const res = await ehr.templateInfo(tempInfo)
            this.indicatorsForm = res.data.kpiTemplate
            this.bussinessDepts = res.data.groups
            // this.templateEmployee.groupId = res.data.groups[0].groupId

            // const oaGroupRef = this.$refs.oaGroup
            //           if (oaGroupRef) {
            //               oaGroupRef.cascaderValue = res.data.groups[0].groupId
            //           }
            this.templateIndicator = res.data.kpiIndicators
            this.indicators = res.data.kpiIndicators
            this.isLoading = false
        },

        async getIndicatorsPage (query) {
            if (query !== '') {
                this.searchIndicatorsData.title = query
                const res = await ehr.getIndicatorsPage(this.searchIndicatorsData)
                this.indicators = res.data || []
            } else {
                this.indicators = []
            }
            console.log('选中列表：' + JSON.stringify(this.templateIndicator))
        },

        addIndicator () {
            console.log('开始查询指标列表')
        },

        deleteIndicatorItem (row) {
            this.templateIndicator.splice(row, 1)
        },

        edit (data) {
            this.$store.commit('setShowParent', 1)
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee/edit?currentTab=edit&sign=edit',
                query: {
                    employeeId: employeeId
                }
            })
        },
        add () {
            //   this.$router.push({
            //     path: "/oa/employee-add",
            //   })
            this.indicatorsForm = {}
            this.operation = true
            this.bussinessDepts = {}
            this.templateIndicator = []
            this.indicatorsForm.templateType = 1
            const oaGroupRef = this.$refs.oaGroup
            if (oaGroupRef) {
                oaGroupRef.cascaderValue = null
            }
            this.showCreateOrUpdate = true
        },
        seeDetail (data) {
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee-detail',
                query: { employeeId }
            })
        },
        dismisson (data) {
            this.operation = false
            console.log('选中的id：' + data.id)
            //
            this.getTemplateInfo(data.id)
            this.showCreateOrUpdate = true
        },
        submitDismisson () {
            this.$refs['indicatorsForm'].validate(async (valid) => {
                if (!valid) {
                    return false
                } else {
                    let templateData = {
                        kpiTemplate: this.indicatorsForm,
                        groups: [],
                        kpiIndicators: this.templateIndicator
                    }

                    if (this.operation) {
                        ehr.addTemplate(templateData)
                    } else {
                        ehr.editTemplate(templateData)
                    }

                    this.showCreateOrUpdate = false
                    this.fetchData()
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                }
            })
        },

        handleDeptChange (val) {
            this.bussinessDepts = []
            let flag = true
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    if (item.isAdmin && flag) {
                        item.groupId = val.id
                        item.groupName = val.groupName
                        flag = false
                    } else {
                        item.isAdmin = false
                    }
                })
            } else {
                this.bussinessDepts.push({
                    groupId: val.id,
                    groupName: val.groupName,
                    isAdmin: true,
                    isPrimary: true,
                    isOwn: false
                })
            }
        },
        async deleteItem (row) {
            console.log('停用数据：' + JSON.stringify(row))
            let that = this
            this.$confirm('确认删除吗？').then(async () => {
                try {
                    const res = await ehr.deleteTempate(row)
                    that.$message.success('删除成功')
                    that.fetchData()
                } catch (error) {
                    console.log(error)
                }
            })
        },
        datasync () {
            this.$axios.fetch('datasyncServer', '/district/cityData').then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.data)
                } else {
                    this.$message.error(res.message)
                }
            })
            this.$axios.fetch('datasyncServer', '/district/employee').then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.data)
                    this.initData()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        showWechat (item) {
            this.$refs['wechat'].showQrCode(item.employeeId, item.employeeName)
        }
    }
}
</script>

<style lang="less" scoped>

/* stylelint-disable */
.name-tips {
  margin-left: 30px;
}
.error-tips {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
}
#quitDiagsImg {
  width: 150px !important;
  height: 197px !important;
}
.el-textarea {
  display: inline-block;
  width: 220px;
}
.center {
  text-align: center;
}
</style>
