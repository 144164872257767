<template>
    <el-upload
        v-bind="$attrs"
        :action="uploadPolicy.uploadUrl"
        :show-file-list="showFileList"
        :accept="accept"
        :before-upload="beforeUpload"
        :on-exceed="handleExceed"
        :on-success="uploadSuccess"
        :on-error="uploadFailed"
        :data="uploadPolicy.policy"
        :headers="uploadPolicy.headers"
    >
        <slot></slot>
    </el-upload>
</template>

<script>
import axios from 'axios'

export default {
    name: 'GbUpload',
    props: {
        value: { // 上传后的下载地址
            type: String,
            default: null
        },
        description: { // 文件描述
            type: String,
            default: null
        },
        fileAcl: { // 文件访问权限，1为私有，2为公共读
            type: Number,
            default: 1
        },
        showFileList: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: 'image/jpg,image/jpeg,image/png'
        },
        size: { // 文件上传限制的大小MB
            type: Number,
            default: null
        }
    },
    data () {
        return {
            uploadPolicy: {
                uploadUrl: window.location.href
            }
        }
    },
    methods: {
        beforeUpload (file) {
            if (!this.accept.includes(file.type)) {
                let message =
                    this.accept === 'video/mp4'
                        ? '请上传正确的格式,视频只支持mp4格式!'
                        : '请上传正确的格式,图片只支持jpg,jpeg,png格式'
                this.$message.error(message)
                return false
            }
            if (this.size && file.size / 1024 / 1024 > this.size) {
                this.$message.error(`上传图片大小不能超过 ${this.size} MB!`)
                return false
            }
            return new Promise((resolve, reject) => {
                axios
                    .fetch('commonServer', '/upload/getOssPolicy', {
                        from: 'web',
                        description: this.description,
                        fileName: file.name,
                        mimeType: file.type,
                        size: file.size,
                        fileAcl: this.fileAcl
                    })
                    .then(res => {
                        this.uploadPolicy = res.data
                        resolve()
                    })
            })
        },
        uploadSuccess (response, file, fileList) {
            this.$emit('input', this.uploadPolicy.downloadUrl)
            this.$emit('success', this.uploadPolicy.downloadUrl, file, fileList)
        },
        uploadFailed (err, file, fileList) {
            this.$message.error(err)
        },
        // 文件超出个数限制时的钩子
        handleExceed () {
            this.$message.error('图片数量超出限制!')
        }
    }
}
</script>
