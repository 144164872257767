<template>
    <div>
        <el-form
            ref="searchForm"
            :inline="true"
            class="page-search"
            :model="searchData"
        >
            <div class="left">
                <el-form-item label="" prop="groupId">
                    <gb-department
                        ref="oaGroup"
                        v-model="searchData.groupId"
                        class="oaGroup"
                        placeholder="请选择组织"
                        api-name="getAllDepts"
                        :disabled-ids="ownedDeptIds"
                        :is-cascader="true"
                        :show-all-levels="false"
                        value-filed="id"
                        :expand-trigger="'click'"
                        label-filed="groupName"
                        @change="handleDeptChange"
                    >
                    </gb-department>
                </el-form-item>

                <el-form-item label="岗位" prop="jobId">
                    <el-select v-model="searchData.jobId" filterable clearable>
                        <el-option
                            v-for="job in jobListAll"
                            :key="job.id"
                            :value="job.id"
                            :label="job.jobName"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="true"
                        @change="dateChange"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="状态" prop="status">
                    <gb-dictionary
                        v-model="searchData.status"
                        category="searchData.status"
                        group="员工岗位招聘需求状态"
                        placeholder="请选择状态"
                        clearable
                    ></gb-dictionary>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="search"
                        >
                            查询
                        </el-button>
                        <el-button
                            icon="el-icon-refresh-right"
                            @click="reset"
                        >
                            重置
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <!-- <span @click="add()"> -->
                <span @click="add()">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang">新增</i>
                </span>
            </div>
            <div class="gb-table">
                <el-table :data="listData" height="500">
                    <el-table-column min-width="50" label="序号" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                (searchData.current - 1) * searchData.size + scope.$index + 1
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" width="50">
                    </el-table-column>

                    <el-table-column
                        prop="jobId"
                        header-align="center"
                        align="center"
                        label="岗位名称"
                    >
                        <template slot-scope="scope">
                            {{ filterJob(scope.row.jobId) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="groupId"
                        header-align="center"
                        align="center"
                        label="所属组织"
                    >
                        <template slot-scope="scope">
                            　<span v-if="filterDept(scope.row.groupId).length>0">
                                 {{ filterDept(scope.row.groupId) }}
                             </span>
                            <span v-else>
                                {{
                                    scope.row.groupName
                                }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="expectedCount"
                        header-align="center"
                        align="center"
                        label="所需人数"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="requirement"
                        header-align="center"
                        align="center"
                        show-overflow-tooltip
                        label="岗位说明"
                    >
                    </el-table-column>
                    <!-- <el-table-column
            prop="availableCount"
            header-align="center"
            align="center"
            label="本次已招聘人数"
          > -->
                    <!-- </el-table-column> -->
                    <el-table-column
                        header-align="center"
                        align="center"
                        :formatter="formatDate"
                        label="预期到岗时间"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        header-align="center"
                        align="center"
                        :formatter="formatCreateDate"
                        label="提出时期"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        header-align="center"
                        align="center"
                        label="状态"
                    >
                        <template slot-scope="scope">
                            　<span>
                                {{
                                    scope.row.status
                                        | dictionaryFilter(vueInstance, "员工岗位招聘需求状态")
                                }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center" min-width="100">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.status != 1"
                                size="mini"
                                type="text"
                                @click="dismisson(scope.row)"
                            >
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :total="dataCount"
                    :current-page="searchData.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchData.size"
                    layout="total, sizes,prev, pager, next, jumper"
                    @current-change="changePage"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>

        <!--新增考核信息-->

        <el-dialog
            :title="operation ? '岗位需求新增' : '岗位需求编辑'"
            :visible.sync="showCreateOrUpdate"
            width="700px"
            center
            top="20vh"
            :modal="true"
            :before-close="cancelDismisson"
        >
            <el-form
                ref="indicatorsForm"
                :rules="quitRules"
                :model="requirementForm"
                label-width="80px"
            >
                <el-row>
                    <el-col
                        :span="12"
                    >
                        <div>
                            <el-form-item label="组织" prop="groupId">
                                <gb-department
                                    ref="oaGroup1"
                                    v-model="requirementForm.groupId"
                                    class="oaGroup1"
                                    placeholder="请选择组织"
                                    api-name="getAllDepts"
                                    :disabled-ids="ownedDeptIds"
                                    :is-cascader="true"
                                    :show-all-levels="false"
                                    value-filed="id"
                                    :expand-trigger="'click'"
                                    label-filed="groupName"
                                    @change="handleDeptChange"
                                >
                                </gb-department>
                            </el-form-item>

                            <el-form-item label="所需人数" prop="expectedCount">
                                <el-input-number
                                    v-model="requirementForm.expectedCount"
                                    :min="1"
                                    :max="100"
                                    label="描述文字"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        <div>
                            <el-form-item label="岗位" prop="jobId">
                                <el-select v-model="requirementForm.jobId" clearable>
                                    <el-option
                                        v-for="job in jobListAll"
                                        :key="job.id"
                                        :value="job.id"
                                        :label="job.jobName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="到岗时间" prop="joinDate">
                                <el-date-picker
                                    v-model="requirementForm.joinDate"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </el-col>
                </el-row>

                <el-form-item label="备注" prop="requirement">
                    <el-input
                        v-model="requirementForm.requirement"
                        type="textarea"
                        :autosize="{ minRows: 6, maxRows: 10 }"
                        style=" display: block; width: 90%;"
                        size="small"
                        placeholder="请输入岗位具体说明"
                        maxlength="500"
                        show-word-limit
                    ></el-input>
                </el-form-item>

                <el-form-item v-if="!operation" label="状态" prop="status">
                    <gb-dictionary
                        v-model="requirementForm.status"
                        category="requirementForm.status"
                        group="员工岗位招聘需求状态"
                        placeholder="请选择状态"
                        clearable
                    ></gb-dictionary>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitDismisson">确 定</el-button>
                <el-button @click="cancelDismisson">取 消</el-button>
            </span>
        </el-dialog>

        <gb-wechat ref="wechat"></gb-wechat>
        <gb-password ref="thePassword" modify-type="admin"></gb-password>
    </div>
</template>

<script>
import permission from '@/utils/permission'
import axios from 'axios'
import utilsTable from '@/utils/table'
import gbWechat from '@/components/wechat-binding'
import gbPassword from '@/components/password'
import oaSvc from '@/services/oa'
import department from '@/components/Department'
import ehr from '@/services/ehr'
import gbDepartment from '@/components/Department'
import gbDictionary from '@/components/dictionary'
import breadSlot from '../components/jobListBreadSlot'
import BreadSlotMixin from 'mixins/breadSlot'
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'EmployeeFile',
    components: { gbPassword, department, gbWechat, gbDepartment, gbDictionary },
    filters: {
        filterStatus: function (value) {
            return value ? '是' : '否'
        },
        dictionaryFilter: gbDictionary.filter
    },
    mixins: [breadSlotMixin],
    data () {
        let that = this
        return {
            vueInstance: this,
            permissionPassword: false,
            permissionWechat: false,
            visibleQuit: false,
            showCreateOrUpdate: false,
            listData: [],
            searchForm: {
                status: null,
                jobId: null,
                groupId: null,
                dateStart: null,
                dateEnd: null

            },
            requirementForm: {
                requirement: null,
                groupId: null,
                jobId: null,
                joinDate: null,
                createTime: null,
                id: null,
                status: null,
                expectedCount: null,
                groupName: null
            },
            dataCount: 0,
            staffForm: {},
            operation: true,
            jobList: [],
            dateRange: [],
            bussinessDepts: [],
            jobListAll: [],
            quitForm: {},
            jobQuery: {},
            value: '',
            options: ['业务指标', '行为指标', '附加项'],
            searchData: {
                employeeNameLike: null,
                joinDateFrom: '',
                joinDateTo: '',
                groupId: null,
                selectStatus: [0, 1],
                size: 20,
                current: 1,
                jobId: null
            },
            registerPlaces: [],
            quitRules: {
                expectedCount: [
                    {
                        required: true,
                        message: '请输入所需人数',
                        trigger: ['blur', 'change']
                    }
                ],
                groupId: [
                    {
                        required: true,
                        message: '请选择组织',
                        trigger: ['blur', 'change']
                    }
                ],
                jobId: [
                    {
                        required: true,
                        message: '请选择岗位',
                        trigger: ['blur', 'change']
                    }
                ],
                joinDate: [
                    {
                        required: true,
                        message: '请选择时间',
                        trigger: ['blur', 'change']
                    }
                ]
            }
        }
    },
    computed: {
        dateValues: {
            get () {
                return [this.searchData.joinDateFrom, this.searchData.joinDateTo]
            },
            set (value) {
                this.searchData.joinDateFrom = value ? value[0] : ''
                this.searchData.joinDateTo = value ? value[1] : ''
            }
        },
        ownedDeptIds () {
            let arr = []
            arr.push(this.requirementForm.groupId)
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    arr.push(item.groupId)
                })
            }
            arr = [...new Set(arr)]
            return arr
        }
    },
    mounted () {
        let data = {
            parentNos: '户籍类型'
        }
        this.$refs.oaGroup
    },
    activated () {
        this.addSlot(breadSlot)
    },
    created () {
        gbDictionary.init(this.vueInstance, ['员工岗位招聘需求状态'])
        this.fetchData()
        this.getJobListAll()
    },

    methods: {
        filterDept (val) {
            let str = ''
            try {
                str = this.$refs.oaGroup.list.filter(
                    (item) => Number(item.id) === Number(val)
                )[0].groupName
            } catch (error) {
                // console.log('error:' + JSON.stringify(error))
            }

            return str
        },
        formatDate (val) {
            // 获取单元格数据
            let data = val.joinDate
            if (data == null) {
                return null
            }
            let dt = new Date(data)
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        },

        formatCreateDate (val) {
            // 获取单元格数据
            let data = val.createTime
            if (data == null) {
                return null
            }
            let dt = new Date(data)
            return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        },

        filterJob (val) {
            let str = ''

            // str = this.jobListAll.filter((item) => Number(item.id) === Number(val))[0]
            //   .jobName
            str = (this.jobListAll.find(item => Number(item.id) === Number(val)) || {})
                .jobName
            return str
        },
        ...utilsTable,
        registerPlaceFormatter (row, column, value, index) {
            let objects = this.registerPlaces
            for (let key of Object.keys(objects)) {
                if (objects[key].itemValue == value) {
                    return objects[key].itemName
                }
            }
        },
        search () {
            this.changePage(1)
        },
        dateChange (val) {
            if (val && val.length > 0) {
                this.searchData.dateStart = val[0]
                this.searchData.dateEnd = val[1]
            }
        },
        handleDeptChange (val) {
            console.log('11111')
            this.bussinessDepts = []
            let flag = true
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    if (item.isAdmin && flag) {
                        item.groupId = val.id
                        item.groupName = val.groupName
                        flag = false
                    } else {
                        item.isAdmin = false
                    }
                })
            } else {
                if (val) {
                    this.bussinessDepts.push({
                        groupId: val.id,
                        groupName: val.groupName,
                        isAdmin: true,
                        isPrimary: true,
                        isOwn: false
                    })
                }
            }
            if (val) {
                this.requirementForm.groupName = val.groupName
                console.log('组织名称：' + val.groupName)
            }
        },
        cancelDismisson () {
            this.$refs.indicatorsForm.clearValidate()
            this.$nextTick(() => {
                Object.keys(this.requirementForm).forEach(item => {
                    this.requirementForm[item] = null
                })
            })

            console.log(this.requirementForm)
            this.fetchData()
            this.$nextTick(() => {
                this.showCreateOrUpdate = false
            })
        },

        submitDismisson () {
            this.$refs['indicatorsForm'].validate(async (valid) => {
                if (!valid) {
                    return false
                } else {
                    console.log('结果：' + JSON.stringify(this.requirementForm))
                    if (this.operation) {
                        this.addJobRequirement()
                    } else {
                        this.updateJobRequirement()
                    }
                    this.fetchData()
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })

                    this.cancelDismisson()

                    this.showCreateOrUpdate = false
                }
            })
        },
        dismisson (row) {
            Object.keys(this.requirementForm).forEach(item => {
                this.requirementForm[item] = row[item]
                if (item === 'status') {
                    this.requirementForm[item] = `${this.requirementForm[item]}`
                }
            })
            // this.requirementForm = JSON.parse(JSON.stringify(row))

            this.operation = false
            this.$nextTick(() => {
                const oaGroupRef = this.$refs.oaGroup1
                oaGroupRef.cascaderValue = row.groupId
            })
            // this.jobListAll = []
            this.jobQuery.groupId = row.groupId
            this.getJobList()
            this.showCreateOrUpdate = true
        },
        searchByStatus (selectStatus) {
            this.searchData.selectStatus = selectStatus
            this.changePage(1)
        },

        async getJobList () {
            const { data = [] } = await oaSvc.getJobList(this.jobQuery)
            this.jobList = data
        },

        async getJobListAll () {
            const { data = [] } = await oaSvc.getJobList()
            this.jobListAll = data
        },
        async addJobRequirement () {
            const res = await ehr.addJobRequirement(this.requirementForm)
        },
        async updateJobRequirement () {
            const res = await ehr.updateJobRequirement(this.requirementForm)
        },

        reset () {
            this.$nextTick(() => {
                const oaGroupRef = this.$refs.oaGroup
                oaGroupRef.cascaderValue = null
            })

            this.searchData.dateStart = null,
            this.searchData.dateEnd = null,
            this.dateRange = []
            // this.$nextTick(() => {
            // Object.keys(this.searchData).forEach(item => {
            //   this.searchData[item] = null
            // })
            //  })
            this.searchData.status = null
            this.searchData.joinDateFrom = ''
            this.searchData.groupId = null
            this.searchData.jobId = null
            this.changePage(1)
        },
        changePage (value) {
            this.searchData.current = value
            this.fetchData()
        },
        handleSizeChange (val) {
            this.searchData.size = val
            this.fetchData()
        },
        async fetchData () {
            const res = await ehr.jobRequirement(this.searchData)
            this.listData = res.data || []
            this.dataCount = res.total
        },

        edit (data) {
            this.$store.commit('setShowParent', 1)
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee/edit?currentTab=edit&sign=edit',
                query: {
                    employeeId: employeeId
                }
            })
        },
        add () {
            console.log(this.requirementForm)
            this.requirementForm.expectedCount = 0
            this.$nextTick(() => {
                const oaGroupRef = this.$refs.oaGroup1
                oaGroupRef.cascaderValue = null
            })

            // this.$nextTick(() => {
            //   const oaGroupRef = this.$refs.oaGroup1
            //   oaGroupRef.cascaderValue = null
            // })
            // console.log('bussinessDepts:'+JSON.stringify(bussinessDepts));

            this.operation = true
            this.showCreateOrUpdate = true

            this.clearValidate()
        },
        clearValidate () {
            console.log('开始清除')

            this.$nextTick(() => {
                this.$refs.indicatorsForm.clearValidate()
            })
        },
        seeDetail (data) {
            let employeeId = data.employeeId
            this.$router.push({
                path: '/oa/employee-detail',
                query: { employeeId }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.name-tips {
    margin-left: 30px;
}

.error-tips {
    font-size: 12px;
    line-height: 1;
    color: #f56c6c;
}

#quitDiagsImg {
    width: 150px !important;
    height: 197px !important;
}

.el-textarea {
    display: inline-block;
    width: 220px;
}

.center {
    text-align: center;
}

.oneLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
