<template>
    <div class="bread-slot">
        <el-dropdown class="item">
            <span class="el-dropdown-link">
                快捷入口<i class="iconfont icon-xiala"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push('/oa/estimate')">考核列表</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/oa/indicators')">考核指标</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/oa/assessTemplate')">考核模板</el-dropdown-item>
                 <el-dropdown-item @click.native="$router.push('/oa/job')">岗位管理</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="item">
            <i class="iconfont icon-huishouzhan1"></i>
            回收站
        </div>
        <div class="item">
            <i class="iconfont icon-shuoming"></i>
            帮助
        </div> -->
    </div>
</template>
<script>
export default {
    name: 'BreadSlot'
}
</script>
<style lang="less" scoped>
.bread-slot {
    display: flex;
    .item {
        cursor: pointer;
        margin-left: 30px;
        font-size: 14px;
        color: #606266;
        &:first-of-type {
            margin-left: 0;
            & > span i {
                font-size: 10px;
                margin-left: 8px;
                color: #909399;
            }
        }
        &:not(:first-of-type) {
            display: flex;
            align-items: center;
            & > i {
                color: #69ACF1;
                margin-right: 8px;
            }
        }
    }
}
</style>
