import axios from '@/utils/https'
import request from '@/utils/request'
const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('documentServer', path, {
        params,
        ...restConfig
    })
export default {
    // ------------文档相关--------------
    getExcelDocumentInfo (query) {
        return axios.fetch('documentServer', '/excelDocument/info', query, 'json')
    },
    // ------------根据key查询--------------
    getExcelDocumentInfoByKey (query) {
        return axios.fetch('documentServer', '/excelDocument/infoByKey', query, 'json')
    },
    // 更新文档信息
    updateExcelInfo (query) {
        return axios.fetch('documentServer', '/excelDocument/update', query, 'json')
    },
    // 上传文档
    uploadFile (query) {
        return axios.fetch('documentServer', '/excelDocument/singleUpload', query)
    },
    // 获取文件下载地址
    getDownloadableUrlByFileNo (query) {
        return axios.fetch('commonServer', '/upload/getDownloadableUrlByFileNo', query)
    }
}
