<template>
    <div class="employee-detail">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-button v-if="!isEdit" @click="close">返回</el-button>
                <el-button
                    v-if="!isEdit"
                    type="primary"
                    @click="handleEdit"
                >
                    编辑
                </el-button>
                <el-button v-if="isEdit" @click="handleCancel">取消</el-button>
                <el-button
                    v-if="isEdit"
                    type="primary"
                    @click="handleSave"
                >
                    保存
                </el-button>
            </div>

            <el-form
                ref="employeeInfoForm"
                :model="employeeInfo"
                :class="{ isEdit }"
                :show-message="false"
                :rules="rules"
            >
                <div class="left">
                    <img
                        class="avartar"
                        src="../../../assets/images/avart.png"
                        alt=""
                    />
                    <el-button v-if="isEdit">
                        上传头像
                    </el-button>

                    <!-- <gb-avatar
                        class="gb-photo"
                        category="employee"
                        :valueId="employeeId"
                        v-model="employeeInfo.employeePhoto"
                    ></gb-avatar> -->
                </div>
                <div class="right">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item
                                label="姓名"
                                label-width="170px"
                                prop="employeeName"
                                :required="isEdit"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.employeeName"
                                ></el-input>
                                <span v-else>{{
                                    employeeInfo.employeeName
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="昵称"
                                label-width="170px"
                                prop="displayName"
                                :required="isEdit"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.displayName"
                                ></el-input>
                                <span v-else>{{
                                    employeeInfo.displayName
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="性别"
                                label-width="170px"
                                prop="gender"
                                :required="isEdit"
                            >
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.gender"
                                    group="性别"
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.gender
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="公司"
                                label-width="170px"
                                prop="companyName"
                            >
                                <el-select
                                    v-if="isEdit"
                                    v-model="employeeInfo.companyId"
                                    filterable
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in companyList"
                                        :key="item.id"
                                        :label="item.partnerName"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                                <span v-if="!isEdit">{{
                                    employeeInfo.companyName
                                }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item
                                label="手机号"
                                label-width="170px"
                                prop="mobilePhone"
                                :required="isEdit"
                            >
                                <span v-if="isEdit">
                                    <el-input
                                        v-if="employeeInfo.employeeId"
                                        v-model.number="employeeInfo.mobilePhone"
                                        disabled
                                        maxlength="11"
                                    ></el-input>
                                    <el-input
                                        v-else
                                        v-model.number="employeeInfo.mobilePhone"
                                        maxlength="11"
                                    ></el-input>
                                </span>

                                <span v-else>{{
                                    employeeInfo.mobilePhone
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="邮箱"
                                label-width="170px"
                                prop="email"
                                :required="isEdit"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.email"
                                    maxlength="50"
                                ></el-input>
                                <span v-else>{{ employeeInfo.email }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="主组织"
                                label-width="170px"
                                prop="groupId"
                                :required="isEdit"
                            >
                                <gb-department
                                    v-show="isEdit"
                                    ref="oaGroup"
                                    v-model="employeeInfo.groupId"
                                    class="oaGroup"
                                    placeholder="请选择组织"
                                    api-name="getAllDepts"
                                    :is-cascader="true"
                                    :show-all-levels="false"
                                    value-filed="id"
                                    :expand-trigger="'click'"
                                    label-filed="groupName"
                                    @change="handleDeptChange"
                                >
                                </gb-department>
                                <span v-if="!isEdit" class="cell">{{
                                    employeeInfo.groupName
                                }}</span>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item
                                label="岗位"
                                label-width="170px"
                                prop="jobId"
                                :required="isEdit"
                            >
                                <el-select
                                    v-if="isEdit"
                                    v-model="employeeInfo.jobId"
                                    filterable
                                    clearable
                                >
                                    <el-option
                                        v-for="job in jobList"
                                        :key="job.id"
                                        :value="job.id"
                                        :label="job.jobName"
                                    ></el-option>
                                </el-select>
                                <span v-else>{{ employeeInfo.jobName }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item

                                label-width="170px"
                                prop="groupId"
                            >
                                <span slot="label">
                                    直属主管
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="选择组织后默认直属主管为所在组织负责人，如果该员工是组织负责人，则取组织的父级组织的负责人"
                                        placement="top-start"
                                    >
                                        <i class="el-icon-warning" style="cursor: pointer;"></i>
                                    </el-tooltip>
                                </span>
                                <!-- <gb-employee
                                    v-if="isEdit"
                                    ref="employee2"
                                    v-model="employeeInfo.parentId"
                                    clearable
                                    placeholder="请选择直属主管"
                                    api-name="getEmlpoyeesList"
                                    :is-cascader="false"
                                    :value-filed="'employeeId'"
                                    :label-filed="'employeeName'"
                                ></gb-employee> -->

                                <el-select
                                    v-if="isEdit"
                                    v-model="employeeInfo.parentId"
                                    filterable
                                    placeholder="请选择"
                                    clearable
                                >
                                    <el-option
                                        v-for="item in employeeList"
                                        :key="item.employeeId"
                                        :label="item.employeeName"
                                        :value="item.employeeId"
                                    >
                                    </el-option>
                                </el-select>
                                <span v-if="!isEdit">{{
                                    employeeInfo.parentName
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="岗位职级"
                                prop="jobLevel"
                                :required="isEdit"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.jobLevel"
                                    maxlength="5"
                                >
                                </el-input>
                                <span v-else>{{ employeeInfo.jobLevel }}</span>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item
                                label="工号"
                                label-width="170px"
                                prop="jobNumber"
                                :required="isEdit"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.jobNumber"
                                    maxlength="10"
                                ></el-input>
                                <span v-else>{{ employeeInfo.jobNumber }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="入职日期"
                                label-width="170px"
                                prop="joinDate"
                                :required="isEdit"
                            >
                                <el-date-picker
                                    v-if="isEdit"
                                    v-model="employeeInfo.joinDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                                <span v-else>{{ employeeInfo.joinDate?employeeInfo.joinDate.substr(0,10):'' }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="员工类型"
                                label-width="170px"
                                prop="employeeType"
                                :required="isEdit"
                            >
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.employeeType"
                                    group="员工类型"
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.employeeType
                                        | dictionaryFilter(this, '员工类型')
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="员工状态"
                                label-width="170px"
                                prop="status"
                                :required="isEdit"
                            >
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.status"
                                    group="员工状态"
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.status
                                        | dictionaryFilter(this, '员工状态')
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="试用期（月）"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model.number="
                                        employeeInfo.probationPeriod
                                    "
                                    maxlength="2"
                                    oninput="value=value.replace(/[^0-9.]/g,'')"
                                >
                                </el-input>
                                <span v-else>{{
                                    employeeInfo.probationPeriod
                                        ? employeeInfo.probationPeriod + '个月'
                                        : '-'
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="离职日期"
                                label-width="170px"
                                prop="dimissionDate"
                            >
                                <el-date-picker
                                    v-if="isEdit"
                                    v-model="employeeInfo.dimissionDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                                <span v-else>{{ employeeInfo.dimissionDate?employeeInfo.dimissionDate.substr(0,10):'' }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="民族"
                                label-width="170px"
                                prop="nation"
                            >
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.nation"
                                    group="民族"
                                    clearable
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.nation
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="婚姻状况" label-width="170px">
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.marriage"
                                    group="婚姻状况"
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.marriage
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="学历" label-width="170px">
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.educational"
                                    group="学历"
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.educational
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="身份证号码"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.cardNo"
                                    maxlength="20"
                                ></el-input>
                                <span v-else>{{ employeeInfo.cardNo }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="籍贯" label-width="170px">
                                <gb-address
                                    v-show="isEdit"
                                    v-model="employeeInfo.birthPlace"
                                    data-type="province"
                                    category="birthPlace"
                                    clearable
                                ></gb-address>
                                <span v-show="!isEdit">{{
                                    employeeInfo.birthPlace
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="政治面貌" label-width="170px">
                                <gb-dictionary
                                    v-show="isEdit"
                                    v-model="employeeInfo.polity"
                                    group="政治面貌"
                                ></gb-dictionary>
                                <span v-show="!isEdit">{{
                                    employeeInfo.polity
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="户籍详细地址"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.registerAddress"
                                    maxlength="100"
                                >
                                </el-input>
                                <span v-else>{{
                                    employeeInfo.registerAddress
                                }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-bottom: 20px;">
                        <el-col :span="6">
                            <el-form-item
                                label="紧急联系人"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.emergencyName"
                                    maxlength="20"
                                >
                                </el-input>
                                <span v-else>{{
                                    employeeInfo.emergencyName
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item
                                label="紧急联系电话"
                                maxlength="20"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.emergencyPhone"
                                >
                                </el-input>
                                <span v-else>{{
                                    employeeInfo.emergencyPhone
                                }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="居住详细地址"
                                label-width="170px"
                            >
                                <el-input
                                    v-if="isEdit"
                                    v-model="employeeInfo.residenceAddress"
                                    maxlength="50"
                                >
                                </el-input>
                                <span v-else>{{
                                    employeeInfo.residenceAddress
                                }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-table
                        :data="bussinessDepts"
                        style="width: 100%;"
                        :header-cell-style="tableHeaderColor"
                        border
                    >
                        <el-table-column
                            prop="groupName"
                            label="组织名称"
                            min-width="170"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span v-if="!scope.row.isAdd">{{
                                    scope.row.groupName
                                }}</span>
                                <gb-department
                                    v-if="scope.row.isAdd"
                                    v-model="scope.row.groupId"
                                    placeholder="请选择组织"
                                    api-name="getAllDepts"
                                    :disabled-ids="ownedDeptIds"
                                    :is-cascader="true"
                                    :show-all-levels="false"
                                    value-filed="id"
                                    :expand-trigger="'click'"
                                    label-filed="groupName"
                                >
                                </gb-department>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="isAdmin"
                            align="center"
                            label="是否是行政组织"
                        >
                            <template slot-scope="scope">
                                <span>
                                    {{ scope.row.isAdmin | filterStatus }}
                                </span>
                            </template>
                        </el-table-column> -->
                        <!-- <el-table-column
                            prop="isPrimary"
                            label="是否是主组织"
                            width="180"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <el-switch
                                    v-if="isEdit"
                                    v-model="scope.row.isPrimary"
                                    active-color="#13ce66"
                                    @change="handlePrimaryChange(scope.$index)"
                                >
                                </el-switch>
                                <span v-else>
                                    {{ scope.row.isPrimary | filterStatus }}
                                </span>
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            prop="isOwn"
                            align="center"
                            label="是否是组织负责人"
                        >
                            <template slot-scope="scope">
                                <el-switch
                                    v-if="isEdit"
                                    v-model="scope.row.isOwn"
                                    active-color="#13ce66"
                                >
                                </el-switch>
                                <span v-else>
                                    {{ scope.row.isOwn | filterStatus }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="isEdit"
                            align="center"
                            label="操作"
                        >
                            <template slot="header">
                                <i
                                    style="font-size: 20px; cursor: pointer;"
                                    class="el-icon-circle-plus"
                                    @click="addDept"
                                ></i>
                            </template>
                            <template slot-scope="scope">
                                <i
                                    v-if="!scope.row.isAdmin"
                                    style="color: red; cursor: pointer;"
                                    class="el-icon-delete-solid"
                                    @click="handleRowDel(scope.$index)"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-form>
        </el-card>

        <!-- <business-dept></business-dept> -->
    </div>
</template>

<script>
import gbDepartment from '@/components/Department'
import gbDictionary from '@/components/dictionary'
import gbAddress from '@/components/address'
import gbEmployee from '@/components/Department'
import { getPartnerInfoList } from 'services/distribution'
import oaSvc from '@/services/oa'
import gbAvatar from '@/components/avatar'
import moment from 'moment'
const phoneChecker = /^1[3456789]\d{9}$/

export default {
    name: 'EmployeeDetail',
    components: { gbDepartment, gbDictionary, gbAddress, gbAvatar, gbEmployee },
    filters: {
        filterStatus: function (value) {
            return value ? '是' : '否'
        },
        dictionaryFilter: gbDictionary.filter
    },
    data () {
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error('邮箱不能为空'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('请输入正确的邮箱格式'))
                }
            }, 100)
        }
        return {
            employeeId: null,
            bussinessDepts: [], // 业务组织列表数据
            employeeInfo: {
                groupId: '',
                oaJob: {},
                employeeGroupList: [],
                probationPeriod: 3,
                status: 0,
                parentId: null,
                displayName: null
            },
            employeeList: {

            },
            companyList: {

            }, // 公司列表
            isEdit: false,
            jobList: [],
            formRules: {
                employeeName: [
                    {
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur'
                    }
                ]
            },
            flag: false,
            rules: {
                email: [
                    { validator: checkEmail, trigger: 'blur' }
                ]
            }
        }
    },
    computed: {
        ownedDeptIds () {
            let arr = []
            arr.push(this.employeeInfo.groupId)
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach(item => {
                    arr.push(item.groupId)
                })
            }
            arr = [...new Set(arr)]
            // console.log(arr)
            return arr
        }
    },
    watch: {
        isEdit (val) {
            if (val) {
                const oaGroupRef = this.$refs.oaGroup
                if (oaGroupRef) {
                    oaGroupRef.cascaderValue = this.employeeInfo.groupId
                }
            }
        },
        employeeId (val) {
            if (val) {
                this.isEdit = false
                this.getEmployeeInfo({ employeeId: val })
            }
        },
        'employeeInfo.groupId' (val) {
            this.flag = true
        },
        'employeeInfo.employeeName' (val) {
            console.log('val+' + val)
            this.employeeInfo.displayName = val
        },
        'employeeInfo.status' (val) {
            console.log('val:  ' + val)
            if (val == 0) {
                this.$set(this.employeeInfo, 'probationPeriod', 3)
                // this.employeeInfo.probationPeriod = 3
                // this.$nextTick(() => {
                //     this.employeeInfo.probationPeriod = 3
                // })
            }
        },
        ownedDeptIds: {
            immediate: true,
            deep: true,
            handler () {
                this.flag = true
            }
        }
    },
    created () {
        gbDictionary.init(this)
        this.employeeId = this.$route.query.employeeId
        this.getEmlpoyeesList()
        this.getJobList()
        this.getCompanyList()
        if (!this.employeeId) {
            this.employeeInfo.employeeType = '1'
            this.employeeInfo.status = '0'
            this.employeeInfo.joinDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.isEdit = true
        } else {
            this.getEmployeeInfo({ employeeId: this.employeeId })
        }
    },
    methods: {
        async getJobList () {
            const { data = [] } = await oaSvc.getJobList()
            this.jobList = data
        },
        // 获取全部员工信息
        async getEmlpoyeesList () {
            let employeeQuery = { selectStatus: [0, 1] }
            const { data = [] } = await oaSvc.getEmlpoyeesList(employeeQuery)
            this.employeeList = data
        },
        async getCompanyList () {
            let partnerQuery = {
                isOwn: true,
                enabled: true
            }
            const { data = [] } = await getPartnerInfoList(partnerQuery)
            this.companyList = data
        },
        async getEmployeeInfo (query) {
            const res = await oaSvc.getEmployeeInfo(query)
            if (res.data) {
                this.employeeInfo = res.data
                if (res.data.companyId == 0) {
                    this.employeeInfo.companyId = null
                }
                if (this.employeeInfo.parentId) {
                    // 查询直属领导姓名
                    var tabList = Array.from(this.employeeList)
                    tabList.forEach(item => {
                        if (this.employeeInfo.parentId == item.employeeId) {
                            this.employeeInfo.parentName = item.employeeName
                        }
                    })
                }
                if (this.employeeInfo.companyId) {
                    var companyList = Array.from(this.companyList)
                    companyList.forEach(item => {
                        if (this.employeeInfo.companyId === item.id) {
                            this.employeeInfo.companyName = item.partnerName
                        }
                    })
                }
                let employeeGroupList = res.data.employeeGroupList
                if (employeeGroupList && employeeGroupList.length > 0) {
                    this.bussinessDepts = employeeGroupList.map(item => {
                        let obj = {}
                        obj.groupId = item.groupId
                        obj.groupName = item.groupName
                        obj.isAdmin = item.isAdmin
                        obj.isPrimary = item.isPrimary
                        obj.isOwn = item.isOwn
                        return obj
                    })
                }
                console.log(this.bussinessDepts, '====bussinessDepts')
            }
        },
        async getAdmin (query) {
            const res = await oaSvc.getAdmin(query)
            let employeeInfoChrooseId = null
            if (res.data && res.data.length > 0) {
                let listData = this.sortArray(res.data, 'level', 'desc')
                for (var i = 0; i < listData.length; i++) {
                    console.log('asdfasdasdada')
                    if ((listData[i].employeeId != this.employeeInfo.employeeId) || (listData[i].parentId == 0 && listData[i].employeeId == this.employeeInfo.employeeId)) {
                        employeeInfoChrooseId = listData[i].employeeId
                        break
                    }
                }
                console.log('employeeInfoChrooseId:' + employeeInfoChrooseId)
                if (!employeeInfoChrooseId) {
                    this.employeeInfo.parentId = this.employeeId
                } else {
                    this.employeeInfo.parentId = employeeInfoChrooseId
                }
            } else {
                this.employeeInfo.parentId = this.employeeId
            }
        },

        // data 数组，key 对象属性, 排序  asc / desc 正序倒序
        sortArray (data, key, order) {
            if (order == 'asc') {
                data.sort((a, b) => a[key] - b[key])
            } else if (order == 'desc') {
                data.sort((a, b) => b[key] - a[key])
            }
            return data
        },
        // switchChange (val, data) {
        //     console.log('val:' + val)
        //     console.log('data:' + JSON.stringify(data))
        //     if (val) {

        //     }
        // },
        handleEdit () {
            // this.bussinessDepts = []
            this.isEdit = true
        },
        handleCancel () {
            if (this.employeeId) {
                this.isEdit = false
                this.$refs['employeeInfoForm'].resetFields()
                this.getEmployeeInfo({ employeeId: this.employeeId })
            } else {
                this.close()
                this.$router.push('/oa/employee')
            }
        },

        handleDeptChange (val) {
            console.log('val-----------------:' + JSON.stringify(val))
            let flag = true
            if (this.bussinessDepts.length > 0) {
                console.log('asddasdadasdadad')
                this.bussinessDepts.forEach(item => {
                    if (item.isAdmin && flag) {
                        item.groupId = val.id
                        item.groupName = val.groupName
                        flag = false
                    } else {
                        item.isAdmin = false
                    }
                })
            } else {
                console.log('sssssssss')
                this.bussinessDepts.push({
                    groupId: val.id,
                    groupName: val.groupName,
                    isAdmin: true,
                    isPrimary: true,
                    isOwn: false
                })
            }
            console.log(' this.bussinessDeptss:' + JSON.stringify(this.bussinessDepts))
            // 开始查询该组织的负责人
            console.log('this.bussinessDepts:' + val.id)
            let queryData = { groupId: val.id }
            this.getAdmin(queryData)
        },
        handleSave () {
            let valid = true
            this.bussinessDepts.forEach((item, index) => {
                if (!item.groupId) {
                    valid = false
                }
            })
            if (!valid) {
                this.$message.warning('组织不能为空')
                return
            }
            if (!phoneChecker.test(this.employeeInfo.mobilePhone)) {
                this.$message.error('手机号码有误，请重新输入')
                return
            }

            // if (!this.employeeInfo.parentId) {
            //     this.$message.warning('直属主管不能为空')
            //     return
            // }

            if (this.employeeInfo.status == -1 && !this.employeeInfo.dimissionDate) {
                this.$message.warning('离职状态，离职时间不能为空')
                return
            }
            this.$refs['employeeInfoForm'].validate(async validate => {
                if (validate) {
                    try {
                        let param = {}
                        if (this.employeeId) {
                            param = Object.assign({}, this.employeeInfo)
                            param.employeeGroupListIsChange = this.flag
                            param.employeeGroupList = this.bussinessDepts
                            await oaSvc.editEmploeeFile(param)
                        } else {
                            param = this.employeeInfo
                            param.employeeGroupList = this.bussinessDepts
                            await oaSvc.addEmploeeFile(param)
                        }
                        this.$message.success('保存成功')
                        this.handleCancel()
                        this.getEmployeeInfo({ employeeId: this.employeeId })
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.$message.warning('请完善员工档案')
                    return false
                }
            })
        },
        /** 关闭按钮 */
        close () {
            this.$store.dispatch('tagsView/delView', this.$route)
            this.$router.push({ path: '/oa/employee' })
        },
        handleRowDel (i) {
            console.log(i)
            this.bussinessDepts.splice(i, 1)
            console.log(this.bussinessDepts)
        },
        addDept () {
            this.bussinessDepts.push({
                groupId: '',
                groupName: '',
                isAdmin: false,
                isPrimary: false,
                isOwn: false,
                isAdd: true
            })
        },
        // 主组织变化
        handlePrimaryChange (i) {
            // console.log(i)
            // console.log(this.bussinessDepts[i].isPrimary)
            if (this.bussinessDepts[i].isPrimary) {
                this.bussinessDepts.forEach((item, index) => {
                    if (index !== i) {
                        item.isPrimary = false
                    }
                })
            }
        },
        // 修改table header的背景色
        tableHeaderColor ({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color: #f3f3f3;color: #333;font-weight: 700;'
            }
        }
    }
}
</script>

<style lang="less" scoped>
.employee-detail {
    /deep/ .el-card {
        display: flex;
        flex-direction: column;
        height: 100%;

        .clearfix {
            text-align: right;
        }

        .el-card__body {
            flex: 1;
            overflow: auto;

            .el-form {
                display: flex;

                .left {
                    display: flex;
                    flex-direction: column;

                    img {
                        width: 160px;
                        height: 160px;
                        margin-bottom: 15px;
                    }
                }

                .right {
                    flex: 1;
                    padding-left: 20px;

                    .add {
                        margin-bottom: 10px;
                        text-align: right;
                    }
                }

                .el-form-item {
                    margin: 0;

                    .el-form-item__label {
                        box-sizing: border-box;
                        height: 54px;
                        padding: 0;
                        margin: 0;
                        font-weight: 700;
                        line-height: 54px;
                        color: #333;
                        text-align: center;
                        border: 1px solid #eee;
                    }

                    .el-form-item__content {
                        box-sizing: border-box;
                        // line-height: 54px;
                        height: 54px;
                        padding: 5px;
                        color: #666;
                        text-align: center;
                        border: 1px solid #eee;

                        .el-cascader,
                        .el-select {
                            width: 100%;
                        }
                    }
                }

                .el-form-item.business-dept {
                    .el-form-item__content {
                        display: flex;
                        text-align: left;
                    }

                    .el-tag {
                        margin: 5px;
                    }

                    .business-dept {
                        width: 350px;
                        // flex: 1;
                        .el-cascader {
                            width: 100%;
                        }

                        .el-cascader__tags {
                            .el-tag {
                                display: flex;
                                align-items: center;

                                > span {
                                    display: inline-block;
                                    width: 100%;
                                    max-width: 120px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                .el-tag__close {
                                    top: 1px;
                                    display: inline-block;
                                    width: 16px;
                                    height: 16px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }
            }

            .el-form.isEdit {
                .el-form-item {
                    .el-form-item__label {
                        padding-left: 40px;
                        text-align: left;
                        border: none;
                    }

                    .el-form-item__content {
                        border: none;
                    }
                }
            }
        }
    }
}

.cell {
    //对超出内容隐藏
    overflow: hidden;
    //显示省略符号来代表被修剪的文本
    text-overflow: ellipsis;
    //文本不会换行，文本会在在同一行上继续，直到遇到 <br> 标签为止。
    white-space: nowrap;
}

</style>
