<template>
    <div class="bread-slot">
        <el-dropdown class="item">
            <span class="el-dropdown-link">
                快捷入口<i class="iconfont icon-xiala"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push('/oa/estimate')">公司管理（暂缓）</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/oa/requirementList')">岗位需求</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/oa/employee')">员工档案</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/oa/job')">岗位管理</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="item">
            <i class="iconfont icon-huishouzhan1"></i>
            回收站
        </div>
        <div class="item">
            <i class="iconfont icon-shuoming"></i>
            帮助
        </div> -->
    </div>
</template>
<script>
export default {
    name: 'BreadSlot'
}
</script>
<style lang="less" scoped>
.bread-slot {
    display: flex;

    .item {
        margin-left: 30px;
        font-size: 14px;
        color: #606266;
        cursor: pointer;

        &:first-of-type {
            margin-left: 0;

            & > span i {
                margin-left: 8px;
                font-size: 10px;
                color: #909399;
            }
        }

        &:not(:first-of-type) {
            display: flex;
            align-items: center;

            & > i {
                margin-right: 8px;
                color: #69acf1;
            }
        }
    }
}
</style>
