<template>
    <div>
        <div v-if="!isNine">
            <!-- 步骤条 -->
            <div style="width: 50%;">
                <steps-bar
                    class="gb-upload-header"
                    :active="active"
                    :groups="steps"
                ></steps-bar>
            </div>
            <div class="detail-content">
                <el-row>
                    <el-col
                        :span="5"
                    >
                        <div class="content-text">
                            考核类型：
                            {{
                                kpiTemplate.templateType
                                    | dictionaryFilter(vueInstance, "KPI考核模板类型")
                            }}
                        </div>
                    </el-col>
                    <el-col
                        :span="5"
                    >
                        <div class="content-text">
                            被考核人：
                            {{ kpiEstimateEmployee.employeeName }}
                        </div>
                    </el-col>
                    <el-col
                        :span="5"
                    >
                        <div class="content-text">
                            考核人：
                            {{ kpiEstimateEmployee.estimatorName }}
                        </div>
                    </el-col>
                    <el-col
                        :span="5"
                    >
                        <div class="content-text">
                            状态：
                            {{
                                kpiEstimateEmployee.status
                                    | filterStatus(kpiEstimateEmployee.status)
                            }}
                        </div>
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <div class="content-text">
                            考核周期：
                            {{ kpiEstimate.dateStart }} ~ {{ kpiEstimate.dateEnd }}
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="gb-table" style="margin-top: 50px">
                <el-form ref="formData">
                    <el-table :data="kpiIndicators">
                        <el-table-column min-width="20" label="序号" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="indicatorType" label="类型" min-width="40">
                            <template slot-scope="scope">
                                　<span>
                                    {{
                                        scope.row.indicatorType
                                            | dictionaryFilter(vueInstance, "KPI考核指标类型")
                                    }}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="title"
                            label="指标名称"
                            min-width="50"
                            align="center"
                        ></el-table-column>
                        <el-table-column
                            prop="description"
                            label="评分说明"
                            min-width="50"
                            align="center"
                        ></el-table-column>

                        <el-table-column
                            prop="scoreStart"
                            label="得分范围"
                            min-width="50"
                            align="center"
                        >
                            　　<template slot-scope="scope">
                                　<span>
                                    {{ scope.row.scoreStart }} ~{{ scope.row.scoreEnd }}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="estimatorName"
                            label="考核人"
                            min-width="20"
                            align="center"
                        >
                            <template>
                                {{ kpiEstimateEmployee.estimatorName }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="indicatorValue"
                            label="评分"
                            min-width="50"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <el-form-item>
                                    <el-input-number
                                        v-model="scope.row.indicatorValue"
                                        placeholder="请打分"
                                        :min="scope.row.scoreStart"
                                        :max="scope.row.scoreEnd"
                                        on-keypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                        :disabled="mark"
                                        @change="countIndicatorValue"
                                    ></el-input-number>
                                </el-form-item>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="detailRemark"
                            label="备注"
                            min-width="100"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <el-form-item>
                                    <el-input
                                        v-model="scope.row.detailRemark"
                                        type="textarea"
                                        placeholder="请输入备注"
                                        maxlength="200"
                                        show-word-limit
                                    ></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
                <div class="desContent">
                    {{ this.kpiTemplate.description }}
                </div>
                <el-divider></el-divider>
                <div class="countClass">最终得分：{{ count }}</div>
                <span slot="footer" style="margin-top: 20px">
                    <el-button v-if="showReject" @click="reject">驳 回</el-button>
                    <el-button
                        v-if="kpiEstimateEmployee.status != 99 && canConform"
                        type="primary"
                        @click="submitDismisson"
                    >确 定</el-button>

                    <el-button
                        v-if="next && kpiEstimateEmployee.status != 99 && canConform"
                        type="primary"
                        @click="submitDismisson(1)"
                    >确 定 下一个</el-button>
                    <el-button @click="handleCancel">取 消</el-button>
                </span>
            </div>
        </div>

        <!--九宫格-->
        <div v-else>
            <steps-bar
                class="gb-upload-header"
                style="margin-top: 20px; width: 50%"
                :active="active"
                :groups="steps"
            ></steps-bar>
            <!-- <div class="detail-content">
        <el-row>
          <el-col :span="6"
            ><div class="content-text">
              考核类型：
              {{
                kpiTemplate.templateType
                  | dictionaryFilter(vueInstance, "KPI考核模板类型")
              }}
            </div></el-col
          >
          <el-col :span="6"
            ><div class="content-text">
              考核人：
              {{ kpiEstimateEmployee.estimatorName }}
            </div></el-col
          >
          <el-col :span="6"
            ><div class="content-text">
              状态：
              {{
                kpiEstimateEmployee.status
                  | filterStatus(kpiEstimateEmployee.status)
              }}
            </div></el-col
          >
          <el-col :span="6"
            ><div class="content-text">
              考核时间：
              {{ kpiEstimateEmployee.createTime }}
            </div></el-col
          >
        </el-row>
      </div> -->

            <el-row>
                <el-col :span="12">
                    <div style="margin-top: 40px; margin-left: 20px; margin-right: 80px">
                        <div class="stepsClass"></div>

                        <div class="nine-time">
                            考核周期：
                            {{ kpiEstimate.dateStart }} ~ {{ kpiEstimate.dateEnd }}
                        </div>

                        <el-row>
                            <el-col
                                :span="24"
                            >
                                <div class="formTable">
                                    <el-form ref="nineFormData" :model="nineFormData" :rules="quitRules">
                                        <el-table
                                            :data="nineFormData.kpiEstimateDetails"
                                            max-height="600"
                                            :header-row-style="{ height: '18px' }"
                                        >
                                            <el-table-column
                                                min-width="30"
                                                label="序号"
                                                align="center"
                                            >
                                                <template slot-scope="scope">
                                                    <span>{{ scope.$index + 1 }}</span>
                                                </template>
                                            </el-table-column>

                                            <el-table-column
                                                prop="employeeName"
                                                label="员工"
                                                min-width="50"
                                                align="center"
                                            ></el-table-column>

                                            <el-table-column
                                                prop="indicatorValue"
                                                label="评分"
                                                min-width="100"
                                                align="center"
                                            >
                                                <template slot-scope="scope">
                                                    <el-form-item :prop="`kpiEstimateDetails.${scope.$index}.indicatorValue`" :rules="quitRules.indicatorValue">
                                                        <el-select
                                                            v-model="scope.row.indicatorValue"
                                                            filterable
                                                            placeholder="请选择分数"
                                                            clearable
                                                            size="small"
                                                            :disabled="canEdit"
                                                        >
                                                            <el-option
                                                                v-for="item in options"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value"
                                                            >
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </template>
                                            </el-table-column>

                                            <el-table-column
                                                prop="detailRemark"
                                                label="备注"
                                                min-width="100"
                                                align="center"
                                            >
                                                <template slot-scope="scope">
                                                    <el-form-item prop="remark">
                                                        <el-input
                                                            v-model="scope.row.remark"
                                                            type="textarea"
                                                            placeholder="请输入备注"
                                                            size="small"
                                                            :disabled="canEdit"
                                                        ></el-input>
                                                    </el-form-item>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form>

                                    <div style="margin-top: 20px">
                                        <span slot="footer">
                                            <el-button
                                                v-if="kpiEstimateEmployee.status != 99"
                                                type="primary"
                                                @click="submitDismissonNine"
                                            >确 定</el-button>
                                            <el-button @click="handleCancel">取 消</el-button>
                                        </span>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>

                <el-col :span="12">
                    <div style="margin-top:100px">
                        <div class="main-right">
                            <div class="grid-data">
                                <!-- 定位 -->
                                <div class="position-top top-text">绩效结果</div>
                                <div class="position-top high-rank">差 10%</div>
                                <div class="position-top medium-rank">中 70%</div>
                                <div class="position-top low-rank">优 20%</div>
                                <div class="position-top custom-arrow-top"></div>
                                <div class="position-bottom bottom-text">发展潜力</div>
                                <div class="position-bottom low-level">低</div>
                                <div class="position-bottom medium-level">中</div>
                                <div class="position-bottom high-level">高</div>
                                <div class="position-bottom custom-arrow-bottom"></div>
                                <!-- 定位 -->
                                <div class="grid-data-item grid-data-item-low">
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            6 熟练员工
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.sixScoreList &&
                                                        account.sixScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：现岗位绩效非常突出，但潜力不足会限制发展
                                    </div>
                                </div>
                                <div class="grid-data-item" @click="showDialog(8)">
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            8 绩效之星
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.eightScoreList &&
                                                        account.eightScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：现岗位绩效表现优异，有一定发展潜力，需要进一步开发
                                    </div>
                                </div>
                                <div class="grid-data-item" @click="showDialog(9)">
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            9 超级明星
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.nineScoreList &&
                                                        account.nineScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：展现出非常优异的绩效及发展潜力，如不安排新的挑战机会，可能出现厌倦或离职
                                    </div>
                                </div>
                                <div
                                    class="grid-data-item grid-data-item-medium"
                                    @click="showDialog(3)"
                                >
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            3 基本胜任
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.threeScoreList &&
                                                        account.threeScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：达到现岗位的绩效要求，但潜力有限，有突出短板，可胜任力有限，后劲不足
                                    </div>
                                </div>
                                <div
                                    class="grid-data-item grid-data-item-low"
                                    @click="showDialog(5)"
                                >
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            5  中坚力量
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.fiveScoreList &&
                                                        account.fiveScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：绩效基本达标，并有一定的发展潜力，是可依靠的稳定贡献者
                                    </div>
                                </div>
                                <div class="grid-data-item" @click="showDialog(7)">
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            7  潜力之星
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.sevenScoreList &&
                                                        account.sevenScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：绩效基本达标，不算非常突出，但潜力突出，可能是由于动力不足或人岗不匹配
                                    </div>
                                </div>
                                <div
                                    class="grid-data-item grid-data-item-high"
                                    @click="showDialog(1)"
                                >
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            1  问题员工
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.oneScoreList &&
                                                        account.oneScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：未达到现岗位绩效要求，潜力也很有限
                                    </div>
                                </div>
                                <div
                                    class="grid-data-item grid-data-item-medium"
                                    @click="showDialog(2)"
                                >
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            2  差距员工
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.twoScoreList &&
                                                        account.twoScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：之前的工作经历显示有一定的潜力，但当前绩效一般，可能尚未适应当前的岗位
                                    </div>
                                </div>
                                <div
                                    class="grid-data-item grid-data-item-medium"
                                    @click="showDialog(4)"
                                >
                                    <el-row>
                                        <el-col :span="12" class="employee-title">
                                            4  待发展者
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="employee-count">
                                                {{
                                                    account &&
                                                        account.fourScoreList &&
                                                        account.fourScoreList.length
                                                }}人
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <div class="employee-detail">
                                        特点：潜力突出但绩效较差，可能到岗时间不长未适应，或动机不足，成长与管理者对工作认知不一致
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <!-- <div class="content-img">
            <div class="img-detail">
              <el-image
                :src="require('@/assets/images/rencai.png')"
                style="width: 800px"
              ></el-image>
            </div></div
        > -->
        </el-col>
        </el-row>
    </div>
    </div>
</template>

<script>
import gbDictionary from '@/components/dictionary'
import stepsBar from '@/components/stepsBar'
import ehr from '@/services/ehr'
import { mapGetters } from 'vuex'
import Image from '../../../components/image.vue'
import permission from '@/utils/permission'
export default {
    components: { gbDictionary, stepsBar },
    filters: {
        filterStatus: function (value) {
            if (value == 0) {
                return '待评分'
            }
            if (value == 10) {
                return '待审核'
            }
            if (value == 20) {
                return '待确认'
            }
            if (value == 99) {
                return '已完成'
            }
        },
        dictionaryFilter: gbDictionary.filter
    },
    data () {
        return {
            // 主键id
            id: null,
            serchDate: {},
            active: 0,
            // 是否是九宫格
            isNine: false,
            // 是否显示驳回按钮
            showReject: false,
            type: 0,
            kpiEstimateEmployee: {},
            kpiIndicators: [],
            kpiEstimateDetails: [],
            kpiTemplate: {},
            listData: [],
            kpiEstimate: {},
            canConform: true,
            next: false,
            steps: ['评分', '审核', '确认', '完成'],
            vueInstance: this,
            value: '',
            canEdit: false,
            searchData: {},
            count: 0,
            pageIndex: null,
            pageSize: null,
            nineFormData: {
                kpiEstimateDetails: []
            },
            showNext: true,
            mark: false,
            account: {
                oneScoreList: [],
                twoScoreList: [],
                threeScoreList: [],
                fourScoreList: [],
                fiveScoreList: [],
                sixScoreList: [],
                sevenScoreList: [],
                eightScoreList: [],
                nineScoreList: []
            },
            quitRules: {
                indicatorValue: [
                    {
                        required: true,
                        message: '请打分',
                        trigger: ['blur', 'change']
                    }
                ]
            },
            options: [
                {
                    value: 1,
                    label: '1 问题员工'
                },
                {
                    value: 2,
                    label: '2 差距员工'
                },
                {
                    value: 3,
                    label: '3 基本胜任'
                },
                {
                    value: 4,
                    label: '4 待发展者'
                },
                {
                    value: 5,
                    label: '5 中坚力量'
                },
                {
                    value: 6,
                    label: '6 熟练员工'
                },
                {
                    value: 7,
                    label: '7 潜力之星'
                },
                {
                    value: 8,
                    label: '8 绩效之星'
                },
                {
                    value: 9,
                    label: '9 超级明星'
                }
            ]
        }
    },
    watch: {
        kpiEstimateDetails: {
            handler: function () {
                console.log('发生变化')
                console.log('kpiEstimateDetails:' + JSON.stringify(this.kpiEstimateDetails))
                let oneScoreList = []
                let twoScoreList = []
                let threeScoreList = []
                let fourScoreList = []
                let fiveScoreList = []
                let sixScoreList = []
                let sevenScoreList = []
                let eightScoreList = []
                let nineScoreList = []
                let data = this.kpiEstimateDetails
                if (data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].indicatorValue == 1) {
                            oneScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 2) {
                            twoScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 3) {
                            threeScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 4) {
                            fourScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 5) {
                            fiveScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 6) {
                            sixScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 7) {
                            sevenScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 8) {
                            eightScoreList.push(data[i])
                        }
                        if (data[i].indicatorValue == 9) {
                            nineScoreList.push(data[i])
                        }
                    }

                    this.account.oneScoreList = oneScoreList
                    this.account.twoScoreList = twoScoreList
                    this.account.threeScoreList = threeScoreList
                    this.account.fourScoreList = fourScoreList
                    this.account.fiveScoreList = fiveScoreList
                    this.account.sixScoreList = sixScoreList
                    this.account.sevenScoreList = sevenScoreList
                    this.account.eightScoreList = eightScoreList
                    this.account.nineScoreList = nineScoreList
                }
            },
            deep: true
        }
    },
    created () {
        gbDictionary.init(this, ['KPI考核模板类型', 'KPI考核指标类型'])
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        this.pageSize = this.$route.query.pageSize
        this.pageIndex = this.$route.query.pageIndex
        this.serchDate.id = this.id
        this.getInfo()
    },
    methods: {
        async getInfo () {
            let res = ''
            if (this.type == 9) {
                res = await ehr.kpiestimateInfo(this.serchDate)
                this.isNine = true
                this.steps = ['评分', '审核', '完成']
            } else {
                res = await ehr.estimateInfo(this.serchDate)
            }

            this.kpiEstimateEmployee = res.data.kpiEstimateEmployee
            this.kpiIndicators = res.data.kpiIndicators
            this.kpiEstimate = res.data.kpiEstimate
            this.active = this.getStatus(this.kpiEstimateEmployee.status)
            if (this.active == 3) {
                // 已完成状态
                this.canEdit = true
            }
            console.log('canEdit:' + this.canEdit)
            this.kpiTemplate = res.data.kpiTemplate
            if (this.kpiTemplate.templateType == 100) {
                this.isNine = true
            } else {
                this.count = this.kpiEstimateEmployee.estimateValue
            }
            this.kpiEstimateDetails = res.data.kpiEstimateDetails
            this.nineFormData.kpiEstimateDetails = res.data.kpiEstimateDetails
            // 判断是否显示驳回按钮
            let currentId = this.currentUser.employeeId
            if (
                this.kpiEstimateEmployee.status == 20 &&
        this.kpiEstimateEmployee.employeeId == currentId
            ) {
                this.showReject = true
            }
            if (this.kpiEstimateEmployee.status === 20 && this.kpiEstimateEmployee.employeeId !== currentId) {
                // 不允许被确认
                this.canConform = false
            }
            let estimatorId = this.kpiEstimateEmployee.estimatorId
            let employeeId = this.kpiEstimateEmployee.employeeId

            console.log('currentId:' + currentId + ' estimatorId:' + estimatorId)
            if (currentId == estimatorId || permission.hasPermission('gbei.oa.hrbp.kpi.listall')) {
                this.next = true
            }
            if (employeeId == currentId) {
                this.mark = true
            }
        },

        getSummaries (param) {
            console.log('sums:' + JSON.stringify(param))
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const values = data.map((item) => Number(item[column.property]))
                if (column.property === 'indicatorValue') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    sums[index]
                }
            })
            return sums
        },
        async reject () {
            this.$confirm('是否确定驳回！！！').then(() => {
                let data = { id: this.kpiEstimateEmployee.id }
                let data1 = { kpiEstimateEmployee: data }
                const res = ehr.reject(data1)
                let path = '/oa/estimate?type=' + this.type
                this.$router.push({
                    path: path
                })
            })
        },

        countIndicatorValue (e) {
            var count = 0
            this.kpiIndicators.forEach(function (element) {
                count = Number(count) + Number(element.indicatorValue)
            })
            this.count = count
        },

        getStatus (param) {
            if (param == 0) {
                return 0
            }
            if (param == 10) {
                return 1
            }
            if (param == 20) {
                return 2
            }
            if (param == 99) {
                return 3
            }
        },

        async kpiestimatePage () {
            const res = await ehr.estimateList(this.searchData)
            this.listData = res.data
            this.getNextId()
        },
        async submitDismissonNine (val) {
            // 首先判断一下账号
            this.$refs['nineFormData'].validate(async (valid) => {
                if (!valid) {
                    console.log('error submit')
                    this.$message.warning('请完善表格内容！')
                    return false
                } else {
                    let currentId = this.currentUser.employeeId
                    let estimatorId = this.kpiEstimateEmployee.estimatorId
                    console.log(
                        'this.kpiEstimateEmployee:' + JSON.stringify(this.kpiEstimateEmployee)
                    )

                    console.log('currentId:' + currentId + '  estimatorId：' + estimatorId)
                    console.log(currentId == estimatorId)
                    console.log(
                        '是否拥有HRBP权限:' +
          permission.hasPermission('gbei.oa.hrbp.kpi.listall')
                    )
                    console.log(
                        'this.kpiEstimateEmployee.status:' + this.kpiEstimateEmployee.status
                    )
                    if (this.kpiTemplate.templateType == 100) {
                        let assessData = {
                            kpiEstimateEmployee: this.kpiEstimateEmployee,
                            kpiIndicators: this.kpiIndicators,
                            kpiTemplate: this.kpiTemplate,
                            kpiEstimateDetails: this.kpiEstimateDetails
                        }
                        const res = await ehr.assess(assessData)
                        // 跳转到列表页
                        let path = '/oa/estimate?type=' + this.type
                        this.$router.push({
                            path: path
                        })
                    } else {
                        if (currentId != estimatorId) {
                            if (
                                this.kpiEstimateEmployee.status == 20 &&
            currentId == this.kpiEstimateEmployee.employeeId
                            ) {
                                console.log('本人考核')
                            } else if (
                                this.kpiEstimateEmployee.status == 10 &&
            permission.hasPermission('gbei.oa.hrbp.kpi.listall')
                            ) {
                                console.log('hrbp考核,有权限')
                            } else {
                                return this.$message.error('没有权限提交')
                            }
                            let assessData = {
                                kpiEstimateEmployee: this.kpiEstimateEmployee,
                                kpiIndicators: this.kpiIndicators,
                                kpiTemplate: this.kpiTemplate,
                                kpiEstimateDetails: this.kpiEstimateDetails
                            }
                            const res = await ehr.assess(assessData)

                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            })
                        } else {
                            let assessData = {
                                kpiEstimateEmployee: this.kpiEstimateEmployee,
                                kpiIndicators: this.kpiIndicators,
                                kpiTemplate: this.kpiTemplate,
                                kpiEstimateDetails: this.kpiEstimateDetails
                            }
                            const res = await ehr.assess(assessData)
                        }
                    }
                }
            })
        },
        async submitDismisson (val) {
            // 首先判断一下账号
            console.log('val==' + val)
            let currentId = this.currentUser.employeeId
            let estimatorId = this.kpiEstimateEmployee.estimatorId
            let employeeId = this.kpiEstimateEmployee.employeeId
            console.log(
                'this.kpiEstimateEmployee:' + JSON.stringify(this.kpiEstimateEmployee)
            )

            console.log('currentId:' + currentId + '  estimatorId：' + estimatorId)
            console.log(currentId == estimatorId)
            console.log(
                '是否拥有HRBP权限:' +
          permission.hasPermission('gbei.oa.hrbp.kpi.listall')
            )
            console.log(
                'this.kpiEstimateEmployee.status:' + this.kpiEstimateEmployee.status
            )
            if (this.kpiTemplate.templateType == 100) {
                let assessData = {
                    kpiEstimateEmployee: this.kpiEstimateEmployee,
                    kpiIndicators: this.kpiIndicators,
                    kpiTemplate: this.kpiTemplate,
                    kpiEstimateDetails: this.kpiEstimateDetails
                }
                const res = await ehr.assess(assessData)
                // 跳转到列表页
                let path = '/oa/estimate?type=' + this.type
                this.$router.push({
                    path: path
                })
            } else {
                if (this.kpiEstimateEmployee.status == 10 && !permission.hasPermission('gbei.oa.hrbp.kpi.listall')) {
                    return this.$message.error('只有hrbp管理员可以审核')
                }
                if (currentId != estimatorId) {
                    if (
                        this.kpiEstimateEmployee.status == 20 &&
            currentId == this.kpiEstimateEmployee.employeeId
                    ) {
                        console.log('本人考核')
                    } else if (
                        this.kpiEstimateEmployee.status == 10 &&
            permission.hasPermission('gbei.oa.hrbp.kpi.listall')
                    ) {
                        console.log('hrbp考核,有权限')
                    } else {
                        return this.$message.error('没有权限提交')
                    }
                    let assessData = {
                        kpiEstimateEmployee: this.kpiEstimateEmployee,
                        kpiIndicators: this.kpiIndicators,
                        kpiTemplate: this.kpiTemplate,
                        kpiEstimateDetails: this.kpiEstimateDetails
                    }
                    const res = await ehr.assess(assessData)

                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                    // 跳转到列表页
                    if (val == 1) {
                        console.log('22222222222222')
                        console.log('跳转下一个')
                        console.log('pageSize' + this.pageSize)
                        console.log('pageIndex' + this.pageIndex)
                        this.searchData.size = this.pageSize
                        this.searchData.current = this.pageIndex
                        this.searchData.status = this.kpiEstimateEmployee.status
                        this.kpiestimatePage()
                        this.serchDate.id = this.id
                    } else {
                        let path = '/oa/estimate?type=' + this.type
                        this.$router.push({
                            path: path
                        })
                    }
                } else {
                    let assessData = {
                        kpiEstimateEmployee: this.kpiEstimateEmployee,
                        kpiIndicators: this.kpiIndicators,
                        kpiTemplate: this.kpiTemplate,
                        kpiEstimateDetails: this.kpiEstimateDetails
                    }
                    const res = await ehr.assess(assessData)
                    // 跳转到列表页

                    if (val == 1) {
                        console.log('3333333333')
                        console.log('跳转下一个')
                        console.log('pageSize' + this.pageSize)
                        console.log('pageIndex' + this.pageIndex)
                        this.searchData.size = this.pageSize
                        this.searchData.current = this.pageIndex
                        this.searchData.status = this.kpiEstimateEmployee.status
                        this.kpiestimatePage()
                        this.serchDate.id = this.id
                    } else {
                        let path = '/oa/estimate?type=' + this.type
                        this.$router.push({
                            path: path
                        })
                    }
                }
            }
        },
        handleCancel () {
            let path = '/oa/estimate?type=' + this.type
            this.$router.push({
                path: path
            })
        },

        /**
     * 过滤list获得下一个id
     */
        getNextId () {
            console.log('开始组装数据')
            let hasNext = false
            if (this.listData.length > 0) {
                this.id = this.listData[0].id
                this.serchDate.id = this.listData[0].id
                console.log('this.serchDate.id:' + this.serchDate.id)
                this.getInfo()
                console.log('this.id:' + this.id)
                this.hasNext = true
            } else {
                let path = '/oa/estimate?type=' + this.type
                this.$router.push({
                    path: path
                })
            }
        }
    },
    computed: {
        ...mapGetters(['currentUser'])
    }
}
</script>

    <style lang="less" scoped>
/* stylelint-disable */

.detail-content {
  margin: 20px;
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
}
.content-text {
  font-size: 14px;
}
.content-img {
  margin: 20px;
  margin-top: 100px;
}
.img-detail {
  text-align: center;
}
.stepsClass {
  display: flex;
  justify-content: center;
}
.desContent {
  margin: 10px;
  font-size: 14px;
}
.countClass {
  text-align: right;
  font-size: 14px;
}
.nine-time {
  margin-bottom: 20px;
  font-size: 14px;
}
.formTable {
  .el-form-item {
    margin-bottom: 0;
  }
}
.main-right {
  display: flex;
  align-items: center;
  margin-left: 100px;
  .grid-data {
    height: 415px;
    position: relative;
    display: grid;
    padding: 4px;
    // width: 470px;
    // height: 260px;
    border-left: 2px solid #c0c4cc;
    border-bottom: 2px solid #c0c4cc;
    grid-template-columns: 190px 190px 190px;
    grid-template-rows: 135px 135px 135px;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
  }
  .grid-data-item {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-size: 14px;
    color: #3595e1;
    line-height: 24px;
    font-weight: 500;
    // height: 100px;
    border: 2px solid #3595e1;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 10px;
    .employee-title {
      font-size: 14px;
      font-weight: 500;
    }
    .employee-count {
      text-align: right;
      font-size: 14px;
      font-weight: 500;
    }
    .employee-detail {
      margin-top: 4px;
      font-size: 12px;
    }
  }
  .grid-data-item-low {
    color: #39a36b;
    border: 2px solid #39a36b;
  }
  .grid-data-item-medium {
    color: #e6a23c;
    border: 2px solid #e6a23c;
  }
  .grid-data-item-high {
    color: #df5a68;
    border: 2px solid #df5a68;
  }
  .position-top,
  .position-bottom {
    position: absolute;
  }
  .position-top,
  .position-bottom {
    font-size: 14px;
    color: #a7b6c3;
    font-weight: 400;
  }
  .position-top {
    left: -110px;
    margin-top: -7px;
    text-align: right;
    width: 100px;
  }
  .top-text,
  .bottom-text {
    color: #606266;
    font-weight: 500;
  }
  .top-text {
    bottom: 100%;
    margin-bottom: -7px;
  }
  .high-rank {
    top: 83%;
  }
  .medium-rank {
    top: 50%;
  }
  .low-rank {
    top: 16%;
  }
  .position-bottom {
    bottom: -30px;
    width: auto;
    margin-left: -7px;
  }
  .bottom-text {
    left: 100%;
    width: 100px;
    margin-left: -20px;
  }
  .low-level {
    left: 16%;
  }
  .medium-level {
    left: 50%;
  }
  .high-level {
    left: 83%;
  }
  .custom-arrow-top,
  .custom-arrow-bottom {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .custom-arrow-top {
    background-image: url(../../../assets/images/grid_arrow_top.png);
    width: 4px;
    height: 10px;
    left: -4px;
    top: -10px;
    margin-top: 0;
  }
  .custom-arrow-bottom {
    width: 10px;
    height: 4px;
    right: -10px;
    bottom: -2px;
    background-image: url(../../../assets/images/grid_arrow_right.png);
  }
}
</style>
