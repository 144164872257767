<template>
    <div>
        <div>
            <el-form
                ref="searchFormNine"
                :inline="true"
                class="page-search"
                :model="searchData"
            >
                <div class="left">
                    <el-form-item label="">
                        <el-input
                            v-model="searchData.jobName"
                            placeholder="岗位名称"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchData.jobNo"
                            placeholder="岗位编码"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="">
                        <gb-employee
                            ref="employee"
                            v-model="searchData.employeeId"
                            clearable
                            placeholder="负责人"
                            api-name="getEmlpoyeesList"
                            :is-cascader="false"
                            :value-filed="'employeeId'"
                            :label-filed="'employeeName'"
                        ></gb-employee>
                    </el-form-item> -->
                    <el-form-item>
                        <el-select
                            v-model="searchData.categoryId"
                            filterable
                            clearable
                            placeholder="请选择岗位类型"
                        >
                            <el-option
                                v-for="item in jobcategoryList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="right">
                    <div class="search-btn">
                        <div class="custom-btn">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handSearch"
                            >
                                查询
                            </el-button>
                            <el-button @click="resetNine">重置</el-button>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="editItem({ enabled: true, groupId: null })">
                    <i
                        class="iconfont icon-benzhouxinzengfenxiaoshang"
                    >添加岗位</i>
                </span>
                <!-- <span @click="$router.push('group')">
                    <i class="el-icon-s-promotion">组织管理</i>
                </span> -->
                <span @click="showPeriodDia">
                    <i class="el-icon-s-promotion">类型管理</i>
                </span>
            </div>
            <div class="gb-table">
                <el-table :data="listData" height="500">
                    <el-table-column
                        label="序号"
                        type="index"
                    ></el-table-column>
                    <!-- <el-table-column
                        prop="id"
                        label="岗位ID"
                        align="center"
                    ></el-table-column> -->
                    <el-table-column
                        prop="jobName"
                        label="岗位名称"
                    ></el-table-column>
                    <el-table-column prop="jobNo" label="岗位编码">
                        <template slot-scope="scope">
                            <span>{{ scope.row.jobNo }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="categoryName" label="岗位类型">
                    </el-table-column>
                    <!-- <el-table-column prop="groupName" label="所属组织">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.groupId | groupNameFilter
                            }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column
                        prop="enabled"
                        label="状态"
                        :formatter="status"
                        align="center"
                    ></el-table-column>
                    <!-- <el-table-column
                        prop="syncStatus"
                        label="是否同步"
                        :formatter="syncStatus"
                        align="center"
                    ></el-table-column> -->
                    <el-table-column
                        prop="updateTime"
                        label="更新时间"
                        align="center"
                    ></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                @click="editItem(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                v-if="scope.row.enabled"
                                size="mini"
                                type="text"
                                @click="removeItem(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog
            :title="editTitle"
            :visible.sync="editVisible"
            width="500px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="itemForm"
                :model="currentItem"
                label-position="left"
                status-icon
                label-width="80px"
            >
                <el-form-item
                    label="岗位名称"
                    prop="jobName"
                    :show-message="false"
                    required
                >
                    <el-input
                        v-model.trim="currentItem.jobName"
                        clearable
                        maxlength="10"
                        show-word-limit
                        type="text"
                        placeholder="岗位名称"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="岗位类型"
                    :show-message="false"
                    prop="categoryId"
                >
                    <el-select
                        v-model="currentItem.categoryId"
                        placeholder="请选择岗位类型"
                        clearable
                    >
                        <el-option
                            v-for="item in jobcategoryList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item
                    label="所属组织"
                    prop="groupId"
                    :show-message="false"
                    required
                >
                    <gb-department
                        style="width:100%"
                        ref="dept"
                        placeholder="请选择所属组织"
                        v-model="currentItem.groupId"
                        valueFiled="id"
                        expandTrigger="click"
                        labelFiled="groupName"
                    >
                    </gb-department>
                </el-form-item> -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item
                            label="是否有效"
                            prop="enabled"
                            :show-message="false"
                            required
                        >
                            <el-switch
                                v-model="currentItem.enabled"
                                :active-value="true"
                                :inactive-value="false"
                            ></el-switch>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-form-item
                            label="是否同步"
                            prop="syncStatus"
                            :show-message="false"
                            required
                        >
                            <el-switch
                                v-model="currentItem.syncStatus"
                                :active-value="1"
                                :inactive-value="0"
                            ></el-switch>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button
                    type="primary"
                    @click="editSubmit()"
                >确 定</el-button>
                <el-button @click="editVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog
            class="add-period"
            :visible.sync="visible"
            :title="title"
            width="25%"
            :lock-scroll="false"
            :close-on-click-modal="false"
        >
            <el-form ref="form" :model="form" :show-message="false">
                <el-table :data="form.list">
                    <el-table-column label="名称" align="center" prop="title">
                        <template slot="header">
                            <span class="starName">名称</span>
                        </template>
                        <template slot-scope="scope">
                            <span v-if="!scope.row.edit">{{
                                scope.row.title
                            }}</span>
                            <el-form-item
                                v-else
                                :prop="`list.${scope.$index}.title`"
                                :rules="tableRules.title"
                            >
                                <el-input
                                    v-model="scope.row.title"
                                    placeholder="请输入类型名称"
                                ></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.edit"
                                type="danger"
                                icon="el-icon-delete"
                                size="mini"
                                @click="handleDel(scope.$index)"
                            ></el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="add-footer">
                    <span @click="handleAddOption">
                        <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
                    </span>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleConfirm">保存</el-button>
                <el-button @click="handleCancel">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import utilsTable from '@/utils/table'
import oaSvc from '@/services/oa'
import gbDepartment from '@/components/Department'
import breadSlot from './components/jobBreadSlot'
import BreadSlotMixin from 'mixins/breadSlot'
const breadSlotMixin = new BreadSlotMixin({ breadSlot })
var that

export default {
    name: 'JobMng',
    components: {
        gbDepartment
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            searchData: {
                jobName: null,
                jobNo: null,
                categoryId: null
            },
            listData: [],
            orglistData: [],
            currentItem: {},
            editVisible: false,
            editTitle: '',
            title: '添加类型',
            deptData: [],
            visible: false,
            jobcategoryList: [],
            form: {
                list: []
            },
            tableRules: {
                title: [{ required: true }],
                periodStart: [{ required: true }],
                periodEnd: [{ required: true }]
            }
        }
    },
    watch: {
        editVisible (newValue, oldValue) {
            if (!newValue) {
                this.currentItem.groupId = ''
            }
        }
    },
    activated () {
        this.addSlot(breadSlot)
    },
    created () {
        this.fetchData()
        this.fetchDeptData()
        this.getJobCategoryList()
    },
    methods: {
        ...utilsTable,
        async fetchData () {
            const { data = [] } = await oaSvc.getJobList({
                isAll: true
            })
            this.orglistData = data
            this.listData = data
        },
        async showPeriodDia () {
            // 请求列表
            const { data = [] } = await oaSvc.jobcategoryList()
            console.log('dataJ:' + JSON.stringify(data))
            this.form.list = data
            this.visible = true
        },

        async fetchDeptData () {
            const { data = [] } = await oaSvc.getAllDepts()
            this.deptData = data
        },

        async getJobCategoryList () {
            const { data = [] } = await oaSvc.jobcategoryList()
            this.jobcategoryList = data
        },
        handSearch () {
            this.listData = this.orglistData
            let jobName = null
            let jobNo = null
            let categoryId = null
            console.log('sss:' + JSON.stringify(this.searchData))
            if (!this.searchData.jobName && !this.searchData.jobNo && !this.searchData.categoryId) {
                this.listData = this.orglistData
                return
            }
            if (this.searchData.jobName) {
                jobName = this.searchData.jobName.toLowerCase()
            }
            if (this.searchData.jobNo) {
                jobNo = this.searchData.jobNo.toLowerCase()
            }
            if (this.searchData.categoryId) {
                categoryId = this.searchData.categoryId.toLowerCase()
            }

            let newListData = [] // 用于存放搜索出来数据的新数组
            if (jobName) {
                this.listData.filter(item => {
                    if (item.jobName.toLowerCase().indexOf(jobName) !== -1) {
                        newListData.push(item)
                    }
                })
                if (!(newListData.length > 0)) {
                    this.listData = []
                    return
                }
            }
            if (jobNo) {
                if (jobName && newListData.length > 0) {
                    let newNoListData = []
                    newListData.filter(item => {
                        if (item.jobNo.toLowerCase().indexOf(jobNo) !== -1) {
                            newNoListData.push(item)
                        }
                    })
                    newListData = newNoListData
                } else {
                    this.listData.filter(item => {
                        if (item.jobNo.toLowerCase().indexOf(jobNo) !== -1) {
                            newListData.push(item)
                        }
                    })
                }
                if (!(newListData.length > 0)) {
                    this.listData = []
                    return
                }
            }
            if (categoryId) {
                if (newListData.length > 0) {
                    let newIdListData = []
                    newListData.filter(item => {
                        if (item.categoryId && item.categoryId == categoryId) {
                            newIdListData.push(item)
                        }
                    })
                    newListData = newIdListData
                } else {
                    this.listData.filter(item => {
                        if (item.categoryId && item.categoryId == categoryId) {
                            newListData.push(item)
                        }
                    })
                }
            }
            this.listData = newListData
            // console.log(‘新数组',newListData)

            // if (this.searchData.groupName) {
            //     console.log(this.searchData.groupName)
            // }
        },

        editItem (item) {
            if (item.groupId) {
                this.$nextTick(() => {
                    this.$refs.dept.cascaderValue = item.groupId
                })
            } else {
                this.$nextTick(() => {
                    this.$refs.dept.cascaderValue = ''
                })
            }
            let form = this.$refs['itemForm']
            if (form) {
                form.resetFields()
            }
            this.getJobCategoryList()

            this.currentItem = _.clone(item)
            console.log('this.currentItem:' + JSON.stringify(this.currentItem))
            if (this.currentItem.categoryId === '0') {
                this.currentItem.categoryId = null
            }
            this.currentItem.deptIdCopy = JSON.stringify(this.currentItem.id)
            this.editTitle = item.id ? '编辑岗位信息' : '新增岗位'
            this.editVisible = true
        },
        editSubmit () {
            let that = this
            this.$refs['itemForm'].validate(async valid => {
                if (!valid) {
                    return false
                }
                console.log(this.currentItem)
                if (this.currentItem.deptIdCopy) {
                    await oaSvc.editJob(that.currentItem)
                } else {
                    await oaSvc.addJob(that.currentItem)
                }
                this.editVisible = false
                this.resetNine()
                that.$message.success('操作成功')
                that.fetchData()
            })
        },
        resetNine () {
            this.$refs['searchFormNine'].resetFields()
            this.$nextTick(() => {
                this.searchData.jobName = null
                this.searchData.jobNo = null
                this.searchData.categoryId = null
            })
            this.listData = this.orglistData
        },
        handleAddOption () {
            this.form.list.push({
                'title': '',
                'edit': true
            })
        },
        handleCancel () {
            this.visible = false
        },
        handleDel (index) {
            this.form.list.splice(index, 1)
        },
        handleConfirm () {
            this.$refs.form.validate(async valid => {
                console.log('valid:' + valid)
                if (valid) {
                    const param = this.form.list.filter(item => item.edit)
                    await oaSvc.addOajobcategory(param)
                    this.$message.success('添加成功！')
                    this.visible = false
                    this.$emit('handle-refresh')
                    this.getJobCategoryList()
                } else {
                    console.log('error submit')
                    this.$message.warning('请完善表格内容！')
                }
            })
        },
        removeItem (item) {
            let that = this
            this.$confirm('确认删除吗？').then(async () => {
                try {
                    await oaSvc.delJob(item)
                    that.$message.success('删除成功')
                    that.fetchData()
                } catch (error) {
                    console.log(error)
                }
            })
        }
    },
    beforeCreate () {
        that = this
    },

    filters: {
        // filterJobNo: val => {
        //     let index = that.jobNoData.findIndex(item => item.value == val)
        //     if (index !== -1) {
        //         return that.jobNoData[index].name
        //     } else {
        //         return ''
        //     }
        // }
        groupNameFilter: val => {
            // console.log(that.deptData);
            let res = that.deptData.find(item => item.id == val)
            return res ? res.groupName : ''
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-form {
    .el-select,
    .el-cascader {
        width: 100%;
    }
}

.add-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #3595e1;

    span {
        cursor: pointer;
    }

    i {
        margin-right: 7px;
    }
}
</style>
